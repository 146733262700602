import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        cred: null,
        userData: null,
        clientData: null,
        authAttempt: false
    },
    reducers: {
        setAuthAttempt(state, action) {
            state.authAttempt = action.payload;
        },
        setCred(state, action) {
            state.cred = action.payload;
        },
        setUserData(state, action) {
            state.userData = action.payload;
        },
        setClientData(state, action) {
            state.clientData = action.payload;
        },
        clearCred(state) {
            state.cred = null;
        },
    },
});

export const { setCred, setUserData, setClientData, clearCred, setAuthAttempt } = authSlice.actions;

export default authSlice.reducer;
