import React from 'react';
//mui
import { FormControl, TextField, Typography } from '@mui/material';


const ExportNameField = ({ exportName, handleExportNamChange }) => {
    return (
        <>
            <Typography sx={{ color: '#979AA1', fontSize: '12px', fontWeight: 500, mb: 1 }}>
                Choose a name:
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <TextField
                    variant="outlined"
                    label="Enter a name"
                    placeholder="Enter a name"
                    value={exportName || ''}
                    onChange={handleExportNamChange}
                    InputProps={{
                        style: {
                        fontSize: '14px',
                        },
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#D3D3D3',
                        },
                        '&:hover fieldset': {
                            borderColor: '#A9A9A9',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#4F46E5',
                        },
                        },
                    }}
                    fullWidth
                />
            </FormControl>
        </>
    );
};

export default ExportNameField;