import React, { useState } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
//mui
import { Menu, MenuItem, Button, Typography } from '@mui/material';
//assets
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
//components
import EmptyState from '../../../../../../components/emptyState/empty_state';
import PreviousScansVideoModal from './components/PreviousScansVideoModal';
//utils
import { capitalizeString } from "../../../../../../utils/main_utils";
//constants
import { para_be } from '../../../../../../config';
import { SeverityLevels } from '../../../../../../constants';


const fetchPastScans = async (rid) => {
    const response = await axios.get(`${para_be}/reports/get_past_scans`, {
        params: { report_id: rid },
        withCredentials: true
    });
    
    if (response.status !== 200) {
        throw new Error('Failed to load past scans');
    }
    
    return response.data.result;
};


const PreviousScansDropDown = ({ rid, handleNotifState }) => {
    const [anchorEl, setAnchorEl] = useState(null); 
    const [modalOpen, setModalOpen] = useState(false);
    const [videoLink, setVideoLink] = useState(null); 
    const [subject, setSubject] = useState(''); 
    
    // Use useQuery to fetch past scans with React Query
    const { data: pastScansList = [], isLoading, isError } = useQuery(
        ['pastScans', rid], 
        () => fetchPastScans(rid), 
        {
            onError: () => {
                handleNotifState({ 
                    text: "Failed to load past scans", 
                    type: SeverityLevels.ERROR
                });
            },
        }
    );
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleSelect = (scan) => {
        setVideoLink(scan.scan_link); 
        setSubject(`${scan.scan_type} ${scan.date}`); 
        setModalOpen(true); 
        handleClose(); 
    };
    
    const isMenuOpen = Boolean(anchorEl);


    return (
        <>
            <Button
                variant="outlined"
                onClick={handleClick}
                sx={{
                    backgroundColor: 'transparent', 
                    color: '#0C1222',
                    fontWeight: 600,
                    justifyContent: 'space-between',
                    marginTop: '1rem',
                    width: '100%',
                    padding: '10px 16px',
                    borderRadius: '8px',
                    border: '1px solid #F2F2F3', 
                    boxShadow: 'none',
                    fontWeight: 700,
                    fontSize: '12px',
                    // '&:hover': { backgroundColor: '#E8E8E9' },
                    textTransform: 'none'
                }}
            >
                View Previous Scans
                {isMenuOpen ? <ChevronDownIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" /> : <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" />} 
            </Button>
            
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableScrollLock
                slotProps={{
                    paper: {
                        sx: {
                            width: '220px', 
                            height: pastScansList.length === 0 ? '150px' : 'inherit',
                            alignContent: pastScansList.length === 0 ?  'center': 'inherit',
                            maxHeight: '250px'
                        },
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {isLoading ? (
                        <MenuItem disabled>
                            <EmptyState text="Loading scans..." size="md" />
                        </MenuItem>
                    ) : isError ? (
                        <MenuItem disabled>
                            <EmptyState text="Error loading scans" size="md" />
                        </MenuItem>
                    ) : pastScansList.length > 0 ? (
                        pastScansList.map((scan) => {
                            const newId = uuidv4();
                            return (
                                <MenuItem key={newId} onClick={() => handleSelect(scan)} sx={{ padding: '12px 12px' }}>
                                    <div className="flex justify-between w-full">
                                        <Typography
                                            variant="h6"
                                            sx={{ fontSize: '12px', fontWeight: 500, color: '#0C1222'}}
                                        >
                                            {capitalizeString(scan.scan_type)}
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            sx={{ fontSize: '12px', fontWeight: 500, color: '#0C1222'}}
                                        >
                                            {scan.date}
                                        </Typography>
                                    </div>
                                </MenuItem>
                            );
                        })
                    ) : (
                        <MenuItem disabled>
                            <EmptyState text="No past scan videos available." size="md" />
                        </MenuItem>
                    )
                }
            </Menu>
            
            {/* Video Modal Component */}
            <PreviousScansVideoModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                subject={subject} 
                videoLink={videoLink} 
            />
        </>
    );
};

export default PreviousScansDropDown;