import { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import OutLogo from './assets/out-logo.png';
import ColapseButtonNavigation from './assets/ColapseButtonNavigation.png';
import ColapseButtonNavigationRight from './assets/ColapseButtonNavigationRight.png'
import Icon from './assets/Icon/Icon';
import Sun from './assets/sun.png';
import Moon from './assets/sun.png';
import LogoParaspot from '../../assets/logo-paraspot.png';
import { setSidebarOpenMobile, setSidebarOpen, getClientLogo } from '../../store/slices/configSlice';
import { useSelector, useDispatch } from 'react-redux';
import useAuthenticate from '../../hooks/useAuthenticate';
import { para_be } from '../../config';
import { useHistory, useLocation } from 'react-router-dom';
import axios from '../../axios/axios';
import SnackBar from '../SnackBar';
import { SeverityLevels } from '../../constants';
import { Link } from 'react-router-dom';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }



  const SideBarNavigation = () => {
    const [enabled, setEnabled] = useState(false);
    const [companyLogo, setCompanyLogo] = useState(null);
    const [notifState, setNotifState] = useState(null);
    const { isMobile } = useSelector(state => state.config);
    const { sidebarOpenMobile, sidebarOpen, clientLogo } = useSelector(state => state.config);
    const { handleSignOut, settingUser } = useAuthenticate();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    // Manage the navigation state
    const [navigation, setNavigation] = useState([
        { name: 'Overview', current: location.pathname === "/management", type: "Speedometer", path: '/management' },
        { name: 'Units', current: location.pathname.startsWith("/units"), type: "Buildings", path: '/units' },
        { name: 'Inspection Journeys', current: location.pathname.startsWith("/journey"), type: "LifeCycle", path: '/journeys' },
        { name: 'Scan Submissions', current: location.pathname === "/scan_submissions", type: "Buildings", path: '/scan_submissions' },
        { name: 'Scans Log', current: location.pathname === "/scans_dashboard", type: "Play", path: '/scans_dashboard' },
        { name: 'Settings', current: location.pathname.startsWith("/settings"), type: "Settings", path: '/settings' },
    ]);

    const handleNavigationClick = (name) => {
        setNavigation(navigation.map(item => ({
            ...item,
            current: item.name === name,
        })));
    };

    const goManagment =()=>{
        history.push('/management')
    }

    const signOut = async () => {
        try {
            const response = await axios.post(`${para_be}/auth/logout`, {}, { withCredentials: true });

            if (response.status === 200) {
                handleSignOut();
                history.push("/");
            } else {
                setNotifState({ text: "Logout Failed", type: "error" });
            }
        } catch (error) {
            console.error(error);
            setNotifState({ text: "Logout Failed", type: "error" });
        }
    };

    useEffect(() => {
        const fetchCompanyLogo = async () => {
            try {
                const logoResponse = await axios.get(`${para_be}/media/get_company_logo`, {
                    withCredentials: true
                });
                if (logoResponse.status === 200 && logoResponse.data.result && logoResponse.data.result.length > 0) {
                    setCompanyLogo(logoResponse.data.result);
                } else {
                    console.log('No logo data found');
                }
            } catch (error) {
                console.error('Error fetching company logo:', error);
            }
        };

        // fetchCompanyLogo();
        dispatch(getClientLogo());
    }, []); 


    

    return (
        <>
            <Transition.Root show={sidebarOpenMobile} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={() => dispatch(setSidebarOpenMobile(false))}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="ease-in duration-200"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <Dialog.Panel className="fixed inset-0 z-40 flex">
                            <div className="relative flex w-full max-w-xs flex-1 transform flex-col bg-white pb-4 pt-5">
                                <div className="absolute right-0 top-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        onClick={() => dispatch(setSidebarOpenMobile(false))}
                                        className="relative ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon className="h-6 w-6 text-white" />
                                    </button>
                                </div>
                                
                                <div className="flex justify-between items-center px-4 mb-2">
                                    <Link onClick={() => {
                                        handleNavigationClick('Overview');
                                        dispatch(setSidebarOpenMobile(false))
                                    }} to="/management">
                                       <img alt="Your Company" src={LogoParaspot} className="h-8 w-auto"  />
                                    </Link>
                                    <div className="ml-4 flex items-center md:ml-6">
                                        {/* <Switch
                                            checked={enabled}
                                            onChange={setEnabled}
                                            className={`mr-2 ${enabled ? 'bg-blue-100' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full pr-4`}
                                        >
                                            <span className="sr-only">Enable notifications</span>
                                            <span className={`${enabled ? 'translate-x-6 bg-indigo-100' : 'translate-x-1 bg-indigo-600'} inline-block h-7 w-7 transform rounded-full transition flex justify-center items-center relative right-1`}>
                                                {enabled ? <img src={Moon} className="h-4 w-4 object-cover" alt="Toggle Icon" /> :
                                                    <img src={Sun} className="h-4 w-4 object-cover" alt="Toggle Icon" />}
                                            </span>
                                        </Switch> */}
                                    </div>
                                </div>

                                <div className="mt-5 flex flex-col justify-between h-full ">
                                    <div className="px-2 mt-2">
                                        <div className="space-y-2">
                                            {navigation.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    to={item.path}
                                                    className={classNames(
                                                        item.current ? 'bg-indigo-600 text-white' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                        'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5'
                                                    )}
                                                    onClick={() => {
                                                        handleNavigationClick(item.name)
                                                        dispatch(setSidebarOpenMobile(false))
                                                    }}
                                                >
                                                    <Icon
                                                        type={item.type}
                                                        className="mr-3"
                                                        aria-hidden="true"
                                                        isSelected={item.current}
                                                    />
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </div>
                                        
                                    </div>
                                    <div className="mt-auto border-t">
                                       <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                                        <img
                                            alt=""
                                            src={clientLogo}
                                            className="h-6 w-6 rounded-full bg-gray-50"
                                        />
                                        <div className='flex flex-col'>
                                            <span aria-hidden="true">{settingUser?.fullName}</span>
                                            <span className="truncate font-normal text-gray-500 text-xs group-hover:text-gray-900">{settingUser?.email}</span>

                                        </div>
                                        <img alt="Your Company" src={OutLogo} className="h-4 cursor-pointer" onClick={signOut} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            {sidebarOpen && !isMobile ? (
                <div className="hidden lg:fixed lg:inset-y-0 xl:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-gray-100 lg:pb-4 lg:pt-5">
                    <div className="flex items-center border-b px-6 pb-3 w-full justify-between">
                    <Link onClick={() => handleNavigationClick('Overview')} to="/management">
                       <img alt="Your Company" src={LogoParaspot} className="h-8 w-auto"  />
                    </Link>
                        
                        <img alt="Your Company" src={ColapseButtonNavigation} className="h-6 w-auto cursor-pointer" onClick={() => dispatch(setSidebarOpen(false))} />
                    </div>
                    <div className="mt-5 flex h-0 flex-1 flex-col overflow-hidden pt-1">
                        <nav className="mt-6 px-3 w-full">
                            <div className="space-y-1">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.path}
                                        className={classNames(
                                            item.current ? 'bg-indigo-600 text-white text-sm' : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                                            'group flex items-center rounded-md px-2 py-2 text-sm font-normal'
                                        )}
                                        onClick={() => handleNavigationClick(item.name)}
                                    >
                                        <Icon
                                            type={item.type}
                                            className="mr-3"
                                            aria-hidden="true"
                                            isSelected={item.current}
                                        />
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                        </nav>

                        {/* User account dropdown */}
                        <div className="mt-auto border-t">
                            <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                                <div className='h-6 w-6 rounded-full bg-gray-50 overflow-hidden'>
                                    <img
                                        alt=""
                                        src={clientLogo}
                                        className="h-full w-full object-cover rounded-full bg-gray-50"
                                    />
                                </div>
                                <div className='flex flex-col'>
                                    <span aria-hidden="true">{settingUser?.fullName}</span>
                                    <span 
                                        className="truncate font-normal text-gray-500 text-xs group-hover:text-gray-900"
                                        style={{ maxWidth: '130px' }}
                                    >
                                        {settingUser?.email}
                                    </span>

                                </div>
                                <img alt="Your Company" src={OutLogo} className="cursor-pointer" onClick={signOut} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : !isMobile && (
                <div className="lg:fixed lg:inset-y-0 lg:flex lg:w-8 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-gray-100 lg:pb-4 lg:pt-5">
                    <div className="absolute border-r rounded-full bg-gray-200 mt-20 h-8 w-8 hover:bg-indigo-900 flex justify-center items-center">
                        <img alt="Your Company" src={ColapseButtonNavigationRight} className="h-6 w-auto cursor-pointer" onClick={() => dispatch(setSidebarOpen(true))} />
                    </div>
                </div>
            )}

            {Boolean(notifState) &&
                <SnackBar
                    open={Boolean(notifState)}
                    handleClose={() => setNotifState(null)}
                    severity={SeverityLevels.ERROR}
                    message={notifState}
                    duration={6000}
                />
            }
        </>
    );
}

export default SideBarNavigation;
