import { useState, useRef, useEffect } from 'react';
import { pdf } from '@react-pdf/renderer';
import { useDispatch, useSelector } from 'react-redux';
import { resetEditMode, reseItemMetadata } from '../../../../../store/slices/reportsSlice';
import ReportPDF from '../../../../../components/pdf/report_pdf/report_pdf';
import { SeverityLevels, ReportsCategory } from '../../../../../constants';

const getDefaultMetadata = (metadata = {}) => ({
    title: metadata?.title,
    description: metadata.description || '',
    price: metadata.price || '0',
});


const usePDFGenerator = (handleNotifState, handleCloseMenu) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const isMounted = useRef(true); // Track if the component is mounted
    
    const { 
        checkboxStates, 
        itemMetadata, 
        header, 
        date, 
        reportData, 
        hasBaseline, 
        editExportMode,
        finishExportMode,
        inspectionType,
        exportReport
    } = useSelector((state) => state.reports);
    const { clientLogo } = useSelector((state) => state.config);
    
    const { customText, selectedCurrency } = exportReport;

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; }; // Cleanup on unmount
    }, []);

    const fetchClientLogoB64 = async () => {
        try {
            const response = await (fetch(clientLogo, { method: 'GET', headers: {'Cache-Control': 'no-cache'} }));
            if (!response.ok) throw new Error('Failed to fetch client logo');
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const handleExport = async () => {
        if (editExportMode && !finishExportMode) {
            handleNotifState({
                text: 'You are in edit mode. Please save your changes before proceeding.',
                type: SeverityLevels.ERROR
            });
            handleCloseMenu();
            return;
        }

        try {
            setLoading(true);
            // const selectedIssues = Object.keys(checkboxStates).filter((object_id) => checkboxStates[object_id]);
            // const selectedMetadata = Object.fromEntries(
            //     selectedIssues.map((object_id) => [object_id, getDefaultMetadata(itemMetadata[object_id])])
            // );
            const sectionKeys = [ReportsCategory.INVENTORY, ReportsCategory.ORGANIZATION, ReportsCategory.DEFECTS];

            const reportItems = sectionKeys.reduce((acc, sectionKey) => {
                const sectionItems = reportData[sectionKey]?.All || reportData[sectionKey] || [];
                const selectedItems = Object.entries((checkboxStates[sectionKey] || {})).filter(([object_id, checked]) => checked).map(([object_id, checked]) => object_id);
                acc[sectionKey] = sectionItems.filter((item) => selectedItems.includes(item.object_id)).map(
                    (item) => {
                        const isDefect = item.issue_type === 'Defect';
                        const metadata = getDefaultMetadata(itemMetadata?.[sectionKey]?.[item.object_id]);
                        return {
                            img: (!hasBaseline || isDefect) ? `${item.media}&zoom_out=true` : item.before_media,
                            ...(hasBaseline ? { img_before: !isDefect ? `${item.media}&zoom_out=true` : item.before_media, before_date: item.before_date ? item.before_date.split("-").reverse().join("-") : item.before_date } : {}),
                            object_id: item.object_id,
                            subject: metadata?.title ? metadata.title : item.subject,
                            issue_type: item.issue_type,
                            description: metadata.description,
                            price: metadata.price,
                        };
                    }
                );
                return acc;
            }, {});
            
            const totalCharge = Object.values(reportItems).flat().reduce((sum, item) => sum + (parseFloat(item.price) || 0), 0);
            const clientLogoB64 = await fetchClientLogoB64();
            
            let pdfDoc;
            try {
                pdfDoc = await pdf(
                    <ReportPDF
                        subject={header}
                        report_datetime={date}
                        clientLogo={clientLogoB64}
                        customText={customText}
                        reportItems={reportItems}
                        chargeAmount={totalCharge}
                        inspectionType={inspectionType}
                        selectedCurrency={selectedCurrency}
                    />
                ).toBlob();
            } catch (err) {
                console.log(err);
                pdfDoc = await pdf(
                    <ReportPDF
                        subject={header}
                        report_datetime={date}
                        clientLogo={clientLogoB64}
                        customText={customText}
                        reportItems={reportItems}
                        chargeAmount={totalCharge}
                        inspectionType={inspectionType}
                        selectedCurrency={selectedCurrency}
                    />
                ).toBlob();
            }
            
            if (isMounted.current) {
                const pdfURL = window.URL.createObjectURL(pdfDoc);
                const downloadLink = document.createElement('a');
                downloadLink.href = pdfURL;
                downloadLink.setAttribute('download', `Report__${header.replace(/\s/g, "_")}_${date.replace(/\//g, "-")}.pdf`);
                downloadLink.click();
            }
        } catch (err) {
            handleNotifState({
                text: 'An error occurred while exporting the PDF.',
                type: SeverityLevels.ERROR
            });
            console.log(err)
        } finally {
            if (isMounted.current) {
                setLoading(false);
                // dispatch(resetEditMode());
                handleCloseMenu();
                // dispatch(reseItemMetadata())
            }
        }
    };

    return {
        loading,
        handleExport,
    };
};

export default usePDFGenerator;