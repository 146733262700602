import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//mui
import { Box, Button, FormControl, InputLabel, ListItemText, MenuItem, Select, styled } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import AddLinkIcon from '@mui/icons-material/AddLink';
//slices
import { openModal } from '../../../../../../store/slices/searchSlice';
//components
import TableOptionsMenu from '../../../../TableWidget/components/TableOptionsMenu';
import CustomButton from '../../../../../buttons/CustomButton';
//assets
import DocsIcon from '../../../../icons/docsIcon';
import PlayIcon from '../../../../icons/playIcon';
import PlainIcon from '../../../../icons/plainIcon';
import UploadIcon from '../../../../icons/uploadIcon';
import FileStack from '../../../../icons/fileStack';
import MaterialSymbols from '../../../../icons/materialSymbols';
import BuildingIcon from '../../../../icons/buildingIcon';
import ScanText from '../../../../icons/scanText';
//utils
import { addTextToClipboard, postReqOptBuilder } from '../../../../../../utils/main_utils';
//constants
import { MODAL_TYPES, SeverityLevels } from '../../../../../../constants';
import { para_be } from '../../../../../../config';


const OptionsMenu = React.memo(({ pid, subject, ext_ref, baseline_date }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const menuItems = [
        ...(baseline_date ? [
                {
                    label: 'Update Baseline Scan',
                    icon: ScanText,
                    onClick: () => history.push(`/${pid}/baseline-scan`),
                }
            ] : [
                {
                    label: 'Create Baseline Scan',
                    icon: BuildingIcon,
                    onClick: () => history.push(`/${pid}/baseline-scan`),
                }
            ]
        ),
        {
            label: 'Upload Baseline',
            icon: UploadIcon,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.UPLOADER_MODAL, pid })),
        },
        {
            label: 'All Reports',
            icon: FileStack,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.ALL_REPORTS_MODAL, subject, pid })),
        },
        {
            label: 'All Tenancies',
            icon: FileStack,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.ALL_TENANCIES_MODAL, subject, pid })),
        },
        {
            label: 'Edit Unit Address',
            icon: MaterialSymbols,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.UPDATE_UNIT_ADDRESS_MODAL, subject, pid, ext_ref })),
        },
        {
            label: 'Edit Reference ID',
            icon: MaterialSymbols,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.UPDATE_UNIT_REFERENCE_MODAL, subject, pid, ext_ref })),
        },
        {
            label: 'Remove Unit',
            icon: MaterialSymbols,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.DELETE_UNIT_MODAL, subject, pid, ext_ref })),
        },
    ];

    return <TableOptionsMenu items={menuItems} />;
});

const StyledSelect = styled(Select)({
    width: '100%',
    padding: '2px 8px',
    fontSize: '14px',
    color: '#0C1222',
});

const SELECTION_OPTIONS = {
    checkin: "Move-In",
    periodical: "Periodical",
    pre_checkout: "Pre Move-Out",
    checkout: "Move-Out",
    turn: "Turn",
}

const ActionsPanelUnits = ({ pid, subject, latest_report_id, changeSetNotifState, scan_id, ext_ref, baseline_date }) => {
    const dispatch = useDispatch();
    const [showShareLinkDD, setShowShareLinkDD] = React.useState(false);
    const [shareLink, setShareLink] = React.useState(scan_id);
    const [loadingShareLink, setLoadingShareLink] = React.useState(false);
    const [selectedType, setSelectedType] = React.useState("checkin");
    
    
    const generateURL = (e) => {
        e.stopPropagation();
        setLoadingShareLink(true);
        
        fetch(para_be + '/units/generate_scan_link', postReqOptBuilder({"pid": pid}))
        .then(response => response.json())
        .then(response => {
            if(response.status === 200) {
                setShareLink(response.result);
                setLoadingShareLink(false);
            } else {
                changeSetNotifState({text: response.msg, type: SeverityLevels.ERROR});
                setLoadingShareLink(false);
            }
        })
        .catch(error => {
            changeSetNotifState({text: "Something went wrong", type: SeverityLevels.ERROR});
            console.log(error);
        });
        
        return false;
    };

    const handleCopy = (e, inspection_type=null) => {
        e.stopPropagation();
        
        // const textToCopy = `${window.location.origin}/checkout/${pid}`;
        const textToCopy = window.location.origin + "/inspection/" + (inspection_type === null ? 'checkout' : inspection_type) + "/" + shareLink;
        const copyStatus = addTextToClipboard(textToCopy);
        if (copyStatus) {
            changeSetNotifState({text: "Link copied successfully!", type: SeverityLevels.SUCCESS});
        } else {
            changeSetNotifState({text: "Failed to copy.", type: SeverityLevels.ERROR});
        }
    
        setShowShareLinkDD(false);
        setTimeout(() => changeSetNotifState(null), 3000);
    };


    return (
        <>
            {baseline_date ? (
                    <>
                        <span
                            className={`cursor-pointer`}
                            onClick={(e) => {
                            e.stopPropagation();
                            dispatch(openModal({ modalName: 'videoModal', subject, pid }));
                            }}
                            title={"View Baseline Scan"}
                        >
                            <PlayIcon />
                        </span>
                    </>
                ) : (
                    <>
                        <span className='cursor-default'>
                            <PlayIcon disabled />
                        </span>
                    </>
                )
            }
            {latest_report_id ? (
                    <>
                        <Link to={`/report/${latest_report_id}`} className="cursor-pointer" title={"View Report"}>
                            <DocsIcon />
                        </Link>
                    </>
                ) : (
                    <>
                        <span className='cursor-default'>
                            <DocsIcon disabled />
                        </span>
                    </>
                )
            }

            <div className='relative'>
                <span className="cursor-pointer" onClick={(e) => { e.stopPropagation(); setShowShareLinkDD(!showShareLinkDD); }} title={"Share"}>
                    <PlainIcon />
                </span>
                {showShareLinkDD &&
                    <div className='absolute top-4 right-0 z-50 mt-2' style={{ background: '#fff' }}>
                        <div 
                            className='flex py-4 px-6 min-w-96 border border-gray-300 btn-radius6 shadow-md max-w-xl' 
                            style={{ backgroundColor: '#fff' }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {shareLink ?
                                <div className=''>
                                    <div>
                                        <Box sx={{ minWidth: 180, maxWidth: 180 }}>
                                            <FormControl variant="standard" fullWidth size="small">
                                                <InputLabel variant="standard" id="inspection-type-select" sx={{ fontSize: "14px", paddingLeft: "8px" }}>
                                                    Inspection Type
                                                </InputLabel>
                                                <StyledSelect
                                                    labelId="inspection-type-select"
                                                    displayEmpty
                                                    disableAutoFocusItem
                                                    value={selectedType}
                                                    onClick={(e) => e.stopPropagation()}
                                                    onChange={(e) => setSelectedType(e.target.value)}
                                                    // renderValue={(selected) => selected && selected.length > 0 ? SELECTION_OPTIONS[selected] : 'Select Inspection Type'}
                                                    renderValue={(selected) => selected && selected.length > 0 ? SELECTION_OPTIONS[selected] : ''}
                                                >
                                                    {Object.entries(SELECTION_OPTIONS).map(([key, value]) => (
                                                        <MenuItem key={key} value={key}>
                                                            <ListItemText
                                                                primary={<span>{value}</span>}
                                                            />
                                                        </MenuItem>
                                                    ))}
                                                </StyledSelect>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="flex flexRow mt-2 py-4 gap-2">
                                        <div className='relative cursor-pointer py-2 px-2' style={{
                                            background: '#E3F2FD',
                                            border: '1px solid #1E88E5',
                                            boxShadow: '0px 4px 10px rgba(66, 165, 245, 0.3)',
                                            borderRadius: '10px'

                                        }}>
                                            <span className='text-ellipsis overflow-x-hidden text-sm font-bold max-w-in300 block'>
                                                {"/inspection/" + (selectedType === null ? 'checkout' : selectedType) + "/" + shareLink}
                                            </span>
                                            <div className='absolute top-0 right-0 flex justify-end my-auto mr-1 mt-1'>
                                                <Button
                                                    title='Re-Generate Link'
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: 'linear-gradient(225.17deg, #3949AB 0.15%, #1E88E5 99.85%)',
                                                        textTransform: 'none',
                                                        borderRadius: '50%',
                                                        padding: '5px',
                                                        minWidth: '20px',
                                                        maxWidth: '26px',
                                                        maxHeight: '26px',
                                                        border: '0.5px solid #132B4F',
                                                        zIndex: 1,
                                                        '&:hover': { 
                                                            backgroundColor: 'linear-gradient(134.83deg, #3949AB 0.15%, #1E88E5 99.85%)',
                                                            transform: 'scale(1.05)',
                                                            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)'
                                                        }
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        generateURL(e);
                                                    }}
                                                >
                                                    <CachedIcon sx={{ width: 16, height: 16 }} />
                                                </Button>
                                            </div>
                                            <div className="absolute right-0 top-0 bottom-0" style={{
                                                    background: 'linear-gradient(90.51deg, rgba(217, 217, 217, 0.1) 0.44%, #BBDEFB 99.56%)',
                                                    borderRadius: '0px 9px 9px 0px',
                                                    width: '130px',
                                                }}
                                            ></div>
                                        </div>

                                        <div className="flex flexRow gap-1 my-auto">
                                            <div>
                                                <Button
                                                    title='Copy Link'
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: 'linear-gradient(225.17deg, #3949AB 0.15%, #1E88E5 99.85%)',
                                                        textTransform: 'none',
                                                        borderRadius: '50%',
                                                        padding: '6px',
                                                        minWidth: '20px',
                                                        maxWidth: '30px',
                                                        border: '0.5px solid #132B4F',
                                                        '&:hover': { 
                                                            backgroundColor: 'linear-gradient(134.83deg, #3949AB 0.15%, #1E88E5 99.85%)',
                                                            transform: 'scale(1.05)',
                                                            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)'
                                                        }
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleCopy(e, selectedType);
                                                    }}
                                                >
                                                    <LinkIcon sx={{ width: 18, height: 18 }} />
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    title='Send Link via Email'
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: 'linear-gradient(225.17deg, #3949AB 0.15%, #1E88E5 99.85%)',
                                                        textTransform: 'none',
                                                        borderRadius: '50%',
                                                        padding: '6px',
                                                        minWidth: '20px',
                                                        maxWidth: '30px',
                                                        border: '0.5px solid #132B4F',
                                                        '&:hover': { 
                                                            backgroundColor: 'linear-gradient(134.83deg, #3949AB 0.15%, #1E88E5 99.85%)',
                                                            transform: 'scale(1.05)',
                                                            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)'
                                                        }
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        dispatch(openModal({ modalName: MODAL_TYPES.SEND_EMAIL_MODAL, subject, pid, ext_ref, shareLink, inspectionType: [selectedType, SELECTION_OPTIONS[selectedType]] }));
                                                        setShowShareLinkDD(false);
                                                    }}
                                                >
                                                    <EmailIcon sx={{ width: 18, height: 18 }} />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className='w-full px-8'>
                                    <CustomButton
                                        variant="contained"
                                        startIcon={<AddLinkIcon sx={{ width: 20, height: 20 }} />}  
                                        onClick={(e) => {
                                            e.preventDefault();
                                            generateURL(e);
                                        }}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: 'linear-gradient(225.17deg, #3949AB 0.15%, #1E88E5 99.85%)',
                                            color: 'white',
                                            fontSize: '12px',
                                            border: '0.5px solid #132B4F',
                                            borderRadius: '16px',
                                            textTransform: 'none',
                                            padding: '6px 12px',
                                            boxShadow: '0px 4px 16px 1px rgba(79, 70, 229, 0.15)',
                                            '&:hover': { 
                                                backgroundColor: 'linear-gradient(134.83deg, #3949AB 0.15%, #1E88E5 99.85%)',
                                                transform: 'scale(1.05)',
                                                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)'
                                            },
                                        }}
                                        loading={loadingShareLink}
                                    >
                                        Generate Link
                                    </CustomButton>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>

            <OptionsMenu
                pid={pid}
                subject={subject}
                ext_ref={ext_ref}
                baseline_date={baseline_date}
            />
        </>
    );
};

export default ActionsPanelUnits;