import React, { useState } from "react";
//mui
import { Box, Typography, Select, MenuItem, Button, CircularProgress } from "@mui/material";


const StepTwo = ({ onConfirm, csvHeaders, constantHeaders, isLoading, handleErrorMessage }) => {
    const [mapping, setMapping] = useState(() => csvHeaders.reduce((acc, header) => ({...acc, [header]: constantHeaders.includes(header) ? header : ""}), {}));

    const handleSelectChange = (field, value) => {
        setMapping((prev) => ({...prev, [field]: value}));
    };

    const handleConfirm = () => {
        const unmappedFields = Object.entries(mapping).filter(([_, value]) => !value);
        
        if (unmappedFields.length > 0) {
            handleErrorMessage(
                `Please map all fields before confirming. Missing fields: ${unmappedFields.map(([key]) => key).join(", ")}` 
            );
        } else {
            onConfirm(mapping); 
        }
    };

    
    return (
        <Box sx={{ padding: "24px", backgroundColor: "#FFFFFF", borderRadius: "8px", maxWidth: "800px", margin: "0 auto" }}>
            {/* Header */}
            <Typography sx={{ fontSize: "20px", fontWeight: 700, marginBottom: "16px" }}>Step 2: Map and Verify</Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400, marginBottom: "24px" }}>
                Map the fields from your uploaded file to the required fields.
            </Typography>
            
            {/* Table for Mapping */}
            <Box sx={{ marginBottom: "24px" }}>
                {csvHeaders.map((header) => (
                    <Box key={header} sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                        {/* Field Column */}
                        <Typography sx={{ width: "40%", fontSize: "14px", fontWeight: 600 }}>{header}</Typography>
                        
                        {/* Dropdown Column */}
                        <Select
                            value={mapping[header]}
                            onChange={(e) => handleSelectChange(header, e.target.value)}
                            displayEmpty
                            sx={{ width: "60%", backgroundColor: "#F9FAFB" }}
                        >
                            <MenuItem value="" disabled>
                                Select Column
                            </MenuItem>
                            {constantHeaders.map((csvHeader) => (
                                <MenuItem 
                                    key={csvHeader} 
                                    value={csvHeader}
                                    disabled={Object.values(mapping).includes(csvHeader)} 
                                >
                                    {csvHeader}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                ))}
            </Box>

            {/* Actions */}
            <Box sx={{ display: "flex", flexDirection: 'row-reverse' }}>
                <Button
                    variant="contained"
                    onClick={handleConfirm}
                    disabled={isLoading} 
                    sx={{ backgroundColor: "#4F46E5", color: "#FFFFFF", fontWeight: 700, textTransform: "none" }}
                >
                    {isLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Confirm & Upload"}
                </Button>
            </Box>
        </Box>
    );
};

export default StepTwo;