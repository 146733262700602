import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
//mui
import { Box, Backdrop, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
//components
import ReportDetails from './components/ReportDetails';
import { useExportReports } from './components/ViewReportHistory/hooks/useExportReports';
import ImageViewerModal from './components/modals/ImageViewerModal';
import TenantMessage from './components/TenantMessage';
import SnackBar from '../../components/SnackBar';
//slices
import { fetchReportsData, setPreviewMode } from '../../store/slices/reportsSlice';
import { postLogEntry } from '../../store/slices/activitySlice';
import { fetchEndUserData } from '../../store/slices/configSlice';
//hooks
import useReportSharedLogic from './hooks/useReportSharedLogic';
//constants
import { PROPERTY_ROLE, ReportsCategory } from '../../constants';


const ReportsPreview = () => {
    const { export_id } = useParams();
    
    const dispatch = useDispatch();
    const { loading, report_id, hasBaseline, header, date, inspectionType, exportReport, filteredData, selectedImageIndex, excludeCostFromExport } = useSelector((state) => state.reports);
    const endUserData = useSelector(state => state.config.endUserData);
    const [loadingExportData, setLoadingExportData] = useState(false);
    const [totalCharge, setTotalCharge] = useState(0);

    const {
        tabIndex,
        notifState,
        handleNotifState,
        handleChangeTabIndex,
        renderTabContent,
    } = useReportSharedLogic();

    const { fetchReport: fetchExport } = useExportReports("", () => {});

    useEffect(() => {
        dispatch(postLogEntry( 
            { 
                activityID: `${report_id}--${export_id}`, activityType: 'report-export', ip: endUserData?.ip, 
                action: {action: "view", target: "export-report"} 
            }
        ));
    }, [dispatch, report_id, endUserData]);

    useEffect(() => {
        setLoadingExportData(true);
        dispatch(setPreviewMode(true));
        fetchExport.mutate(export_id);
        dispatch(fetchEndUserData());
    }, []);

    useEffect(() => {
        if (exportReport.currentExportReport) {
            if (exportReport.currentExportReport?.report_id) {
                setLoadingExportData(false);
                dispatch(fetchReportsData({ rid: exportReport.currentExportReport?.report_id }));
            }
            setTotalCharge(Object.keys(exportReport.currentExportReport.exported_data.items).reduce((acc, k) => [...acc, ...Object.values(exportReport.currentExportReport.exported_data.items[k])], []).reduce((sum, item) => sum + (item?.price ? parseFloat(item.price) : 0), 0));
            // setTotalCharge(Object.values(exportReport.currentExportReport.exported_data.items).reduce((sum, item) => sum + (item?.price ? parseFloat(item.price) : 0), 0));
        }
    }, [dispatch, exportReport]);

    const inspectionTitle = () => {
        if (inspectionType === 'move_in' || inspectionType === "checkin") return 'Move In';
        if (inspectionType === 'move_out' || inspectionType === "checkout") return 'Move Out';
        if (inspectionType === 'semi_annual') return 'Semi-Annual';
        if (inspectionType === 'annual') return 'Annual';
        if (inspectionType === 'turn') return 'Turn';
        return 'Move Out';
    }
    
    if (exportReport?.existence === false) {
        return <Redirect to="/404"/>
    }

    return (
        <>
            <Helmet>
                <title>Paraspot | {inspectionTitle()} Inspection Report - {header}</title>
                <meta name="description" content={`${inspectionTitle()} inspection report of the unit ${header} from ${date}`}/>
                <meta property="og:title" content={`Paraspot | ${inspectionTitle()} Report - ${header}`}/>
                <meta property="og:description" content={`${inspectionTitle()} report of the unit ${header} from ${date}`}/>
            </Helmet>
            {/* Backdrop for Loading State */}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading || loadingExportData} />
            <div className='flex gap-4 flex-col sm:p-24'>
                {/* Video and Guest Info Section */}
                <ReportDetails rid={exportReport.currentExportReport?.report_id} handleNotifState={handleNotifState} />

                <div className='flex flex-col rounded-lg shadow-md w-full p-4'>
                    <TenantMessage 
                        customText={exportReport.currentExportReport?.exported_data?.message} 
                        totalCharge={
                            !excludeCostFromExport && exportReport.propertyRole === PROPERTY_ROLE.RESIDENT ? 
                                ((exportReport.currentExportReport?.exported_data?.currency === 'eur' ? '€' : '$') + Number(totalCharge).toLocaleString('en-US')) :
                                null
                        }
                    />
                </div>

                {/* Tabs and Export Controls Section */}
                <div className='flex flex-col rounded-lg shadow-md w-full p-4'>
                    <TabContext value={tabIndex}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <div className='flex flex-col-reverse pb-4' style={{ justifyContent: 'space-between' }}>
                                <TabList
                                    onChange={handleChangeTabIndex}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: '#4F46E5'
                                        }
                                    }}
                                    sx={{
                                        '& .MuiTabs-root': {
                                            color: '#5C6270',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                        },
                                        '& .Mui-selected': {
                                            color: '#4F46E5 !important',
                                        }
                                    }}
                                >
                                    <Tab label={`Inventory`} sx={{ textTransform: 'capitalize' }} value="1" />
                                    <Tab label={`Damages`} sx={{ textTransform: 'capitalize' }} value="2" />
                                    {hasBaseline && <Tab label={`Organization`} sx={{ textTransform: 'capitalize' }} value="3" />}
                                </TabList>
                            </div>
                        </Box>

                        <TabPanel value="1">{renderTabContent(ReportsCategory.INVENTORY)}</TabPanel>
                        <TabPanel value="2">{renderTabContent(ReportsCategory.DEFECTS)}</TabPanel>
                        <TabPanel value="3">{renderTabContent(ReportsCategory.ORGANIZATION)}</TabPanel>
                        
                        {/* Image Viewer Modal */}
                        {selectedImageIndex !== null && (
                            <ImageViewerModal
                                assetItems={filteredData}
                            />
                        )}
                    </TabContext>
                </div>
            </div>

            {notifState && 
                <SnackBar
                    open={!!notifState}
                    handleClose={() => handleNotifState(null)}
                    severity={notifState.type}
                    message={notifState.text}
                    duration={notifState?.duration ?? 6000}
                />
            }
        </>
    )
};

export default ReportsPreview;