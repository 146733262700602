import React from 'react';
//mui
import { Box, Typography, Button } from '@mui/material';


const StepThree = ({ handleClose, uploadResponse }) => (
    <Box
        sx={{
            padding: "24px",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            maxWidth: "800px",
            margin: "0 auto",
            textAlign: "center",
        }}
    >
        {/* Header */}
        <Typography sx={{ fontSize: "20px", fontWeight: 700, marginBottom: "16px" }}>
            Upload Successful 🎉
        </Typography>
        
        {/* Description */}
        <Typography sx={{ fontSize: "14px", fontWeight: 400, marginBottom: "24px" }}>
            {uploadResponse?.msg === "OK" ? "Your file has been successfully uploaded." : uploadResponse?.msg}
        </Typography>
        
        {/* Actions */}
        <Box>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                sx={{
                    backgroundColor: "#4F46E5",
                    color: "#FFFFFF",
                    fontWeight: 700,
                    textTransform: "none",
                    padding: "8px 24px",
                }}
            >
                Return to page 
            </Button>
        </Box>
    </Box>
);

export default StepThree;