import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
//mui
import { Box, Button, TextField, MenuItem, Tooltip, CircularProgress, Menu } from '@mui/material';
//slices
import {
    setCustomText, toggleEditMode,
    EDIT_STATE, setPreviewMode, setLinkModal,
    resetCurrentExportReport, setSelectedCurrency,
    setCurrentExportId
} from '../../../../store/slices/reportsSlice';
//hooks
import usePDFGenerator from './hooks/usePDFGenerator';
//utils
import { dateTimeFormater } from '../../../../utils/date_utils';
//constants
import { para_be } from '../../../../config';
import { PROPERTY_ROLE, ReportsCategory, SeverityLevels } from '../../../../constants';


const createExportReportAPI = async (requestBody) => {
    console.log(requestBody);
    const response = await axios.post(
        `${para_be}/export_reports/set_export_report`,
        requestBody,
        { withCredentials: true }
    );

    if (response.status !== 200 || response.data.status !== 200) {
        throw new Error(response.data.msg || 'Failed to create export report');
    }

    return response.data;
};

const ExportControls = ({ handleNotifState, rid }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const customTextRef = useRef("");
    
    const { isMobile, clientLogo } = useSelector((state) => state.config);
    const { userData, clientData } = useSelector((state) => state.auth);
    const { checkboxStates, itemMetadata, finishExportMode, exportReport, includeVideoInExport, excludeCostFromExport } = useSelector((state) => state.reports);

    const { customText, selectedCurrency } = exportReport;

    const areSelectionsEmpty = useMemo(() => (
        (Object.keys(checkboxStates).length === 0 || Object.keys(checkboxStates).reduce((acc, k) => [...acc, Object.values(checkboxStates[k])], []).every(value => value === false)) &&
        (Object.keys(itemMetadata).length === 0 ||
            Object.keys(itemMetadata).reduce((acc, k) => [...acc, Object.values(itemMetadata[k])], []).every(meta =>
                (meta.description === "") &&
                (meta.price === "" || meta.price == '0')
            )
        ) &&
        customText === ""
    ), [checkboxStates, itemMetadata, customText]);

    const { mutate: createExportReport, isLoading: exportReportLoading } = useMutation(
        createExportReportAPI, // API call function
        {
            onSuccess: (data) => {
                handleNotifState({
                    text: 'Export report saved successfully',
                    type: SeverityLevels.SUCCESS
                });
                console.log('Export report created:', data);
                dispatch(toggleEditMode(EDIT_STATE.END));
                dispatch(setCurrentExportId(data.result.export_id));
                // Reset currentExportReport
                // dispatch(resetCurrentExportReport());
            },
            onError: (error) => {
                handleNotifState({
                    text: error.message || 'Failed to save export report',
                    type: SeverityLevels.ERROR
                });
            },
        }
    );

    const handleCloseMenu = () => setAnchorEl(null);
    
    const { loading: pdfLoading, handleExport } = usePDFGenerator(handleNotifState, handleCloseMenu);
    
    const handleShareClick = (e) => setAnchorEl(e.currentTarget);

    const handleCustomTextChange = (e) => {
        dispatch(setCustomText(e.target.value));
    };

    const handleSave = () => {
        const exportedData = {
            management: {
                userData, clientData, clientLogo
            },
            includeVideo: includeVideoInExport,
            excludeCost: excludeCostFromExport,
            message: customText,
            currency: selectedCurrency,
            // items: Object.keys({...checkboxStates, ...itemMetadata}).reduce(
            //     (acc, k) => ({
            //         ...acc, 
            //         [k]: itemMetadata?.[k] ? itemMetadata[k] : { description: '', price: '' }
            //     }), {}
            // ),
            items: {
                [ReportsCategory.INVENTORY]: Object.keys({...checkboxStates[ReportsCategory.INVENTORY], ...itemMetadata[ReportsCategory.INVENTORY]}).reduce(
                    (acc, k) => ({
                        ...acc, 
                        [k]: itemMetadata[ReportsCategory.INVENTORY]?.[k] ? itemMetadata[ReportsCategory.INVENTORY][k] : { description: '', price: '' }
                    }), {}
                ),
                [ReportsCategory.DEFECTS]: Object.keys({...checkboxStates[ReportsCategory.DEFECTS], ...itemMetadata[ReportsCategory.DEFECTS]}).reduce(
                    (acc, k) => ({
                        ...acc, 
                        [k]: itemMetadata[ReportsCategory.DEFECTS]?.[k] ? itemMetadata[ReportsCategory.DEFECTS][k] : { description: '', price: '' }
                    }), {}
                ),
                [ReportsCategory.ORGANIZATION]: Object.keys({...checkboxStates[ReportsCategory.ORGANIZATION], ...itemMetadata[ReportsCategory.ORGANIZATION]}).reduce(
                    (acc, k) => ({
                        ...acc, 
                        [k]: itemMetadata[ReportsCategory.ORGANIZATION]?.[k] ? itemMetadata[ReportsCategory.ORGANIZATION][k] : { description: '', price: '' }
                    }), {}
                ),
            }
        };
        const todayDate = dateTimeFormater(new Date(), 'dd/mm/yyyy HH:MM:SS');

        const requestBody = {
            report_id: rid,
            export_id: exportReport.currentExportReport?.export_id || null,
            export_type: exportReport.propertyRole,
            exported_data: exportedData,
            metadata: {
                name: exportReport.exportName,
                date_created: todayDate,
            },
        };

        // Call the mutation
        createExportReport(requestBody);
    };


    return (
        <Box
            display="flex"
            gap={2}
            sx={{
                justifyContent: isMobile ? '' : 'space-between',
                width: '100%',
                flexDirection: isMobile ? 'column-reverse' : 'row',
                marginBottom: '1.5rem'
            }}
        >
            <div className='w-full'>
                <TextField
                    inputRef={customTextRef}
                    id="exp-pdf-customText"
                    placeholder='Add any custom text that you would like to include in the report export'
                    multiline
                    rows={3}
                    variant='outlined'
                    value={customText}
                    onChange={handleCustomTextChange}
                    sx={{ width: isMobile ? '100%' : '60%' }}
                />

                {exportReport.propertyRole === PROPERTY_ROLE.RESIDENT && 
                    <div className='sm:w-6/12 w-full mt-4 flex items-center justify-between'>
                        {/* Dropdown for Currency Selection */}
                        <TextField
                            id="exp-pdf-currency"
                            select
                            label="Currency"
                            value={selectedCurrency} // Controlled state
                            onChange={(e) => {
                                dispatch(setSelectedCurrency(e.target.value));
                                console.log("Currency selected:", e.target.value);
                            }}
                            variant='outlined'
                            sx={{ width: 200 }}
                        >
                            <MenuItem value="usd">USD</MenuItem>
                            <MenuItem value="eur">EUR</MenuItem>
                        </TextField>
                    </div>
                }
            </div>

            <Box
                display="flex"
                gap={1}
                sx={{
                    width: isMobile ? '100%' : '30%',
                    flexDirection: 'row-reverse',
                    ml: 2,
                    mb: 2,
                    mt: 1,
                    pr: 2,
                }}
            >
                <Box position='relative' display='inline-block'>
                    <Tooltip
                        title="You must first add something to share"
                        disableHoverListener={!areSelectionsEmpty}
                        sx={{
                            position: 'absolute', 
                            left: 0, 
                            zIndex: 1
                        }}
                    >
                        <span>
                            <Button
                                sx={{
                                    backgroundColor: '#4F46E5',
                                    color: '#FFFFFF',
                                    height: '36px',
                                    fontSize: '12px',
                                    fontWeight: 700,
                                    textTransform: 'none',
                                    borderRadius: '4px',
                                    padding: '6px 12px',
                                    '&:hover': {
                                        backgroundColor: '#3C3EB8',
                                    },
                                    '&.Mui-disabled': {
                                        backgroundColor: '#4F46E5',
                                        color: '#FFFFFF',
                                    },
                                    width: 'inherit'
                                }}
                                disabled={areSelectionsEmpty || exportReportLoading} // Disable button while loading
                                onClick={handleSave}
                            >
                                {exportReportLoading ? (
                                        <CircularProgress size={20} color='inherit' />
                                    ) : (
                                        'Save'
                                    )
                                }
                            </Button>
                         </span>
                    </Tooltip>
                </Box>
                
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    sx={{ mt: 1 }}
                    disableScrollLock
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    disabled={pdfLoading}
                >
                    {/* <MenuItem
                        onClick={() => alert("Send Report feature not implemented")}
                    >
                        Send Report
                    </MenuItem> */}
                    <MenuItem
                        onClick={() => {
                            dispatch(setLinkModal({ open: true }));
                            handleCloseMenu();
                        }}
                    >
                        Copy Link
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleExport(customTextRef.current?.value || '')}
                    >
                        Download PDF
                    </MenuItem>
                </Menu>
                <Box position='relative' display='inline-block'>
                    <Tooltip
                        title="You must save your new edition"
                        disableHoverListener={finishExportMode && !areSelectionsEmpty}
                        sx={{ position: 'absolute', left: 0, zIndex: 1 }}
                    >
                        <span>
                            <Button
                                sx={{
                                    backgroundColor: '#F2F2F3',
                                    color: '#0C1222',
                                    height: '36px',
                                    fontSize: '12px',
                                    fontWeight: 700,
                                    textTransform: 'none',
                                    borderRadius: '4px',
                                    padding: '6px 12px',
                                    border: '1px solid #E0E0E0',
                                    '&:hover': { backgroundColor: '#E0E0E0' }
                                }}
                                disabled={!finishExportMode || areSelectionsEmpty}
                                onClick={handleShareClick}
                            >
                                {pdfLoading ? <CircularProgress size={20} color='inherit' /> : 'Share'}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
                
                <Button
                    sx={{
                        backgroundColor: '#F2F2F3',
                        height: '36px',
                        color: '#0C1222',
                        fontSize: '12px',
                        fontWeight: 700,
                        textTransform: 'none',
                        borderRadius: '4px',
                        padding: '6px 12px',
                        border: '1px solid #E0E0E0',
                        '&:hover': {
                            backgroundColor: '#E0E0E0',
                        }
                    }}
                    disabled={!finishExportMode || areSelectionsEmpty}
                    onClick={() => dispatch(setPreviewMode(true))}
                >
                    Preview
                </Button>
                
            </Box>
        </Box>
    );
};

export default ExportControls;