import React, { useState } from 'react';
//mui
import { Button } from '@mui/material';
import ReplayIcon from "@mui/icons-material/Replay";


const ItemImage = ({ item, index, handleOpenModal, dispatch }) => {
    const [imgError, setImgError] = useState(false);
    const [retryCount, setRetryCount] = useState(0);

    const handleRetry = () => {
        setImgError(false);
        setRetryCount((prev) => prev + 1);
    };
    
    
    return (
        <div
            className="w-full h-32 bg-gray-100 rounded-md overflow-hidden mb-2 cursor-pointer"
            onClick={() => dispatch(handleOpenModal(index))}
        >
            {!imgError ?
                <img
                    src={`${item.media}${item.media.includes('?') ? "&" : "?"}retry=${retryCount}`}
                    alt={item.subject}
                    className="w-full h-full object-cover"
                    onError={() => {
                        if (retryCount < 2) {
                            handleRetry();
                        } else {
                            setImgError(true);
                        }
                    }}
                /> :
                <div className="w-full flex flex-col items-center justify-center bg-gray-100 p-4">
                    <p className="text-gray-500 text-lg">Failed Loading</p>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ReplayIcon />}
                        onClick={handleRetry}
                        sx={{
                            marginTop: '8px'
                        }}
                    >
                        Retry
                    </Button>
                </div>
            }
        </div>
    );
};

export default ItemImage;