import React from 'react';
//mui
import { Checkbox as MuiCheckbox } from '@mui/material';


const Checkbox = ({ checked, onChange, sx, ...props }) => {
    return (
        <MuiCheckbox
            checked={checked}
            onChange={onChange}
            sx={{
                '&.Mui-checked': {
                    color: '#4F46E5',
                },
                ...sx,  // allows additional styles to be merged
            }}
            {...props}  // pass any additional props like `size`, `color`, etc.
        />
    );
};

export default Checkbox;