import React from 'react';
import { useDispatch } from 'react-redux';
//mui
import { Box, Typography, CircularProgress, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
//slices
import { setLinkModal } from '../../../../../../store/slices/reportsSlice';
//assets
import ParkOutline from '../../../../icons/parkoOutline';
import LinkIcon from '../../../../icons/linkIcon';
//utils
import { capitalizeString } from '../../../../../../utils/main_utils';


const PreviousVersions = ({ fetchReports, exportReport, handleViewReport, handleOpenModal }) => {
    const dispatch = useDispatch();

    return (
        <>
            <Typography sx={{ color: '#979AA1', fontSize: '12px', fontWeight: 500, mb: 1, mt: 2 }}>
                ACTIVE EXPORTS
            </Typography>

            {fetchReports.isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={24} />
                    </Box>
                ) : exportReport.allExportReports.length === 0 ? (
                    <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#979AA1', textAlign: 'center', mt: 2 }}>
                        No export reports available.
                    </Typography>
                ) : (
                    exportReport.allExportReports.map((report, index) => (
                        <div className="flex justify-between w-full" key={index}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#0C1222' }}>
                                    {report.metadata?.name || 'Unnamed'} - 
                                </Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#0C1222', marginLeft: '4px' }}>
                                    {report.metadata?.date_created?.split(' ')[0] || 'N/A'}
                                </Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#4F46E5', backgroundColor: '#F2F2F3', border: '1px solid #4F46E51A', padding: '1px 8px', marginLeft: '12px' }}>
                                    {capitalizeString(report.type) || 'N/A'}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                {/* <IconButton sx={{ color: '#4F46E5' }}>
                                    <ParkOutline />
                                </IconButton> */}

                                <Button
                                    onClick={() => handleViewReport(report.id)}
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        color: '#4F46E5',
                                        textTransform: 'none',
                                        padding: 0,
                                        minWidth: 'auto',
                                    }}
                                >
                                    Edit Report
                                </Button>
                                
                                <IconButton
                                    sx={{ color: '#4F46E5', paddingLeft: '4px', paddingRight: '4px' }}
                                    onClick={() => {
                                        dispatch(setLinkModal({ open: true, export_id: report.id}))
                                    }}
                                >
                                    <LinkIcon stroke="#4F46E5" />
                                </IconButton>

                                {/* <IconButton sx={{ color: '#4F46E5', paddingLeft: '2px', paddingRight: '2px', paddingTop: '6px', paddingBottom: '6px' }}>
                                    <DownloadIcon stroke="#4F46E5" fontSize='small' />
                                </IconButton> */}
                                
                                <IconButton sx={{ color: '#FF0000', paddingLeft: '2px', paddingRight: '2px', paddingTop: '6px', paddingBottom: '6px' }} onClick={() => handleOpenModal(report.id)}>
                                    <CloseIcon fontSize='small' />
                                </IconButton>
                            </Box>
                        </div>
                    ))
                )
            }
        </>
    );
};

export default PreviousVersions;