import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
//components
import StepOne from "../steps/StepOne/StepOne";
import StepTwo from "../steps/StepTwo";
import StepThree from "../steps/StepThree";
//constants
import { para_be } from '../../../../config';


const constantHeaders = [
    "Unit", "Reference", "Building", "Community", "Tenant Full Name", "First Name", "Last Name",
    "Salutation", "Email", "Phone", "Move-In", "Move-Out", "Deposit", "Rent", "Currency",
];


const BulkUploadWizard = ({ handleClose, handleErrorMessage, onComplete }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [uploadedFile, setUploadedFile] = useState(null); // Centralized uploaded file state
    const [csvHeaders, setCsvHeaders] = useState([]); // Centralized headers state
    const [fileData, setFileData] = useState([]); // Centralized file rows
    const [isLoading, setIsLoading] = useState(false); // Loader state
    const [uploadResponse, setUploadResponse] = useState(null);

    const endUserData = useSelector((state) => state.config.endUserData);


    const handleNext = () => { setCurrentStep((prev) => prev + 1) };
    const handleBack = () => { setCurrentStep((prev) => prev - 1) };

    const handleComplete = async (mappedHeaders) => {
        try {
            setIsLoading(true);
            // Map CSV content to new headers
            const newHeaders = Object.entries(mappedHeaders).filter(([csvHeader, header]) => header !== "").map(([csvHeader, header]) => header);
            const newData = fileData.map((row) => {
                return newHeaders.map((header) => {
                    const csvColumn = Object.entries(mappedHeaders).filter(([csvHeader, constantHeader]) => constantHeader === header)[0][0];
                    // const csvColumn = mappedHeaders[header];
                    return row[csvHeaders.indexOf(csvColumn)] || "";
                });
            });

            const prepCol = (c) => {
                let out = c;
                if (c.includes('"')) {
                    out = out.replace(/"/g, '""');
                }
                if (c.includes(',') || c.includes('"')) {
                    out = `"${out}"`;
                }
                return out;
            }
            // Create Blob content for CSV file
            const blobContent = [newHeaders.join(","), ...newData.map((row) => row.map((col) => prepCol(col)).join(","))].join("\n");
            const blob = new Blob([blobContent], { type: "text/csv" });
            
            // Create FormData to send the file and additional data
            const formData = new FormData();
            formData.append("csv_file", blob, "mapped_data.csv");
            formData.append("ip", endUserData?.ip);
            
            // Send the FormData using Axios
            const { data } = await axios.post(
                `${para_be}/units/bulk_upload`,
                formData,
                {
                    withCredentials: true, // Include cookies in the request
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            
            // Success feedback
            setUploadResponse(data);
            setIsLoading(false); 
            handleNext(); 
            if (onComplete) onComplete();
        } catch (error) {
            setIsLoading(false); 
            console.error("Error uploading the file:", error);
            handleErrorMessage(error.response?.data?.msg || "An error occurred during file upload."); 
        }
    };


    return (
        <>
            {currentStep === 1 && (
                <StepOne
                    uploadedFile={uploadedFile}
                    setUploadedFile={setUploadedFile}
                    onNext={handleNext}
                    headers={constantHeaders}
                    csvHeaders={csvHeaders}
                    fileData={fileData}
                    setFileData={setFileData}
                    setCsvHeaders={setCsvHeaders}
                    handleErrorMessage={handleErrorMessage}
                />
            )}
            {currentStep === 2 && (
                <StepTwo
                    onBack={handleBack}
                    onConfirm={handleComplete}
                    csvHeaders={csvHeaders}
                    constantHeaders={constantHeaders}
                    isLoading={isLoading}
                    handleErrorMessage={handleErrorMessage}
                />
            )}
            {currentStep === 3 && (
                <StepThree
                    uploadResponse={uploadResponse}
                    handleClose={handleClose}
                />
            )}
        </>
    );
};

export default BulkUploadWizard;