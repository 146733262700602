import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
//mui
import { Box, Typography, Button, TextField, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
//components
import BaseModal from '../../../../../components/Modal';
import { base_url } from '../../../../../config';


const LinkModal = ({ open, handleClose, url, export_id }) => {
    const { exportReport } = useSelector((state) => state.reports);
    const [copied, setCopied] = useState(false);
    
    const newUrl = base_url + "/external-access/report/" + (export_id ? export_id : exportReport.currentExportReport?.export_id);
    
    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(newUrl);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };
    
    
    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            minWidth={400}
            maxWidth={600}
        >
            <Box p={3}>
                <Typography variant="h6" gutterBottom>
                    New Link
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Here's the modified link for your report:
                </Typography>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bgcolor="#f9f9f9"
                    borderRadius={2}
                    p={1}
                    sx={{ border: '1px solid #ddd' }}
                >
                    <TextField
                        variant="outlined"
                        size="small"
                        value={newUrl}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        sx={{ mr: 1 }}
                    />
                    <IconButton color="primary" onClick={handleCopyToClipboard}>
                        <ContentCopyIcon />
                    </IconButton>
                </Box>
                {copied && (
                    <Typography variant="caption" color="green" mt={1}>
                        Link copied to clipboard!
                    </Typography>
                )}
                <Box mt={2} textAlign="right">
                    <Button variant="contained" color="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Box>
            </Box>
        </BaseModal>
    );
};

export default LinkModal;