import { Box, Typography } from '@mui/material';
import InsertChartIcon from '@mui/icons-material/InsertChart'; 

const EmptyState = () => {
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100%',
                paddingTop: '50px' 
            }}
        >
            {/* Circle with Icon */}
            <Box 
                sx={{
                    backgroundColor: '#F5F6F7', 
                    borderRadius: '50%', 
                    width: '56px', 
                    height: '56px', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center'
                }}
            >
                <InsertChartIcon sx={{ color: '#4F46E5', fontSize: '28px' }} /> 
            </Box>
            
            {/* Header Text */}
            <Typography 
                sx={{ 
                    color: '#0C1222', 
                    fontSize: '16px', 
                    fontWeight: 700, 
                    marginTop: '16px'  
                }}
            >
                No findings for this category
            </Typography>
        </Box>
    );
};

export default EmptyState;