import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//mui
import { Box, Typography } from '@mui/material';
//components
import CommentSection from './components/CommentSection';
import ItemImage from './components/ItemImage'
import ItemLabel from './components/ItemLabel';
//slices
import { setCheckboxStates, setItemDescription, handleOpenModal, setItemPrice, toggleCommentMode } from '../../../../store/slices/reportsSlice';
import Checkbox from '../../../../components/Checkbox';
import { PROPERTY_ROLE } from '../../../../constants';


const COMMENT_MODE = {
    EDIT: 'Edit Comment',
    ADD: 'Add Comment'
};

const getBoxStyles = (commentModes, tabView, itemMetadata, item, editExportMode, finishExportMode) => {
    let currentCommentMode = commentModes[item.object_id] || COMMENT_MODE.ADD;
    // If the item has metadata and the description/price has value, set the comment mode to edit
    if (itemMetadata?.[tabView]?.[item.object_id] && ((itemMetadata[tabView][item.object_id]?.description || "") !== "" || (itemMetadata?.[tabView]?.[item.object_id]?.price || "") !== "")) {
        currentCommentMode = COMMENT_MODE.EDIT;
    }
    const isAddMode = currentCommentMode === COMMENT_MODE.ADD && (editExportMode && !finishExportMode);
    const isEditMode = currentCommentMode === COMMENT_MODE.EDIT && (editExportMode && !finishExportMode);
    
    const boxHeight = isAddMode ? '270px' : 'inherit';
    const boxBackgroundColor = isEditMode ? 'rgba(127, 40, 11, 0.02)' : 'transparent';
    
    return { boxHeight, boxBackgroundColor, currentCommentMode };
};

const AssetOverview = ({ header, assetItems = [], assetItemsIndexes = [], setNotifState }) => {
    const dispatch = useDispatch();
    const { 
        finishExportMode, checkboxStates, report_id, exportReport, tabView,
        previewMode, editExportMode, itemMetadata, commentModes
    } = useSelector((state) => state.reports);


    const handleCheckboxChange = (object_id, event) => {
        dispatch(setCheckboxStates({ object_id, checked: event.target.checked }));
    };

    const handleDescriptionChange = (object_id, event) => {
        dispatch(setItemDescription({ object_id, description: event.target.value }));
    };

    const handlePriceChange = (object_id, event) => {
        dispatch(setItemPrice({ object_id, price: event.target.value }));
    };

    const toggleComment = (object_id) => {
        dispatch(toggleCommentMode(object_id));
    };

    // Use useMemo to memorize the previewItems calculation
    const previewItems = useMemo(() => (
        previewMode ? 
            assetItems.map((item) => ({
                ...item,
                description: itemMetadata?.[tabView]?.[item.object_id]?.description || '',
                price: itemMetadata?.[tabView]?.[item.object_id]?.price || ''
            })) 
            : 
            assetItems
    ), [previewMode, assetItems, itemMetadata]);

    if (!Array.isArray(previewItems) || previewItems.length === 0) {
        return null;
    }


    return (
        <div className='mb-6 p-4' style={{ border: '1px solid #F2F2F3', borderRadius: '8px' }}>
            {/* Header */}
            <div className='flex'>
                {editExportMode &&
                    <Checkbox
                        checked={previewItems.every((item) => checkboxStates?.[tabView]?.[item.object_id])}
                        onChange={(e) => {
                            previewItems.forEach((item) => handleCheckboxChange(item.object_id, e));
                        }}
                        sx={{ padding: '0', height: '24px', width: '24px', marginRight: '4px' }}
                    />
                }
                <Typography 
                    variant='h6' 
                    sx={{ 
                        fontSize: '16px', 
                        fontWeight: 700, 
                        color: '#0C1222', 
                        marginBottom: '16px' 
                    }}
                >
                    {header} ({previewItems.length})
                </Typography>
            </div>

            {/* Grid layout for items */}
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'>
                {previewItems.map((item, index) => {
                    const { boxHeight, boxBackgroundColor, currentCommentMode } = getBoxStyles(commentModes, tabView, itemMetadata, item, editExportMode, finishExportMode);

                    return (
                        <Box
                            key={item.object_id}
                            className='bg-white rounded-lg shadow-md p-4 flex flex-col'
                            sx={{ 
                                minWidth: '200px',
                                height: boxHeight,
                                backgroundColor: boxBackgroundColor,
                                maxWidth: '300px',
                                boxSizing: 'border-box',
                            }}
                        >
                            {/* Image with click handler to open modal */}
                            <ItemImage
                                item={item}
                                index={assetItemsIndexes[index]}
                                handleOpenModal={handleOpenModal}
                                dispatch={dispatch}
                            />

                            {/* Label and Icon Row */}
                            <ItemLabel
                                report_id={report_id}
                                item={item}
                                index={assetItemsIndexes[index]}
                                previewMode={previewMode}
                                editExportMode={editExportMode}
                                finishExportMode={finishExportMode}
                                checkboxStates={checkboxStates}
                                tabView={tabView}
                                handleCheckboxChange={handleCheckboxChange}
                                dispatch={dispatch}
                                setNotifState={setNotifState}
                            />

                            {/* Item Subject */}
                            <Typography
                                variant='body2'
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    color: '#0C1222',
                                }}
                            >
                                {(editExportMode || previewMode) ? itemMetadata?.[tabView]?.[item.object_id]?.title || item.subject : item.subject}
                            </Typography>

                            {/* Comment Section or Preview Description */}
                            {!previewMode ?
                                <CommentSection
                                    currentCommentMode={currentCommentMode}
                                    item={item}
                                    handleDescriptionChange={handleDescriptionChange}
                                    handlePriceChange={handlePriceChange}
                                    toggleCommentMode={() => toggleComment(item.object_id)}
                                />
                                :
                                <>
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#0C1222',
                                            opacity: 0.56,
                                        }}
                                    >
                                        {itemMetadata?.[tabView]?.[item.object_id]?.description || ''}
                                    </Typography>
                                    {(!exportReport.currentExportReport?.exported_data?.excludeCost && exportReport.propertyRole === PROPERTY_ROLE.RESIDENT) &&
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 400,
                                                color: '#0C1222',
                                                opacity: 0.56,
                                            }}
                                        >
                                            Cost: {exportReport.currentExportReport?.exported_data?.currency === 'eur' ? '€' : '$'}{Number((itemMetadata?.[tabView]?.[item.object_id]?.price && itemMetadata?.[tabView]?.[item.object_id]?.price !== "") ? itemMetadata[tabView][item.object_id]?.price : 0).toLocaleString('en-US')}
                                        </Typography>
                                    }
                                </>
                            }
                        </Box>
                    );
                })}
            </div>
        </div>
    );
};

export default AssetOverview;
