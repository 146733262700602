import React from 'react';
import { useSelector } from 'react-redux';
import { utils as XLSX_utils, read as XLSX_read } from 'xlsx';
//mui
import { Box, Button, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { dateTimeFormater } from '../../../../../utils/date_utils';


const StepOne = ({ uploadedFile, setUploadedFile, onNext, headers, csvHeaders, fileData, setFileData, setCsvHeaders, handleErrorMessage }) => {
    const { isMobile } = useSelector((state) => state.config);


    const downloadTemplate = () => {
        try {
            const blobContent = [headers.join(",")].join("\n");
            const blob = new Blob([blobContent], { type: "text/csv" });
            const link = document.createElement('a');
            const objectURL = (window.webkitURL || URL).createObjectURL(blob);
            link.href = objectURL;
            link.download = `ParaspotBulkUploadTemplate.csv`;
    
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
            setTimeout(() => {
                console.log("Revoking object url to free up memory");
                (window.webkitURL || URL).revokeObjectURL(objectURL);
            }, 5000);
        } catch (error) {
            handleErrorMessage("An error occurred while downloading the template file.");
        }
    }

    const processFile = (file) => {
        // Validate file extension
        const validExtensions = /\.(csv|xlsx|xls)$/i;
        if (!validExtensions.test(file.name)) {
            handleErrorMessage("Invalid file format. Please upload a CSV, XLSX, or XLS file.");
            return false; 
        }
    
        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX_read(data, { type: "array", cellDates: true });
                
                // Ensure workbook contains sheets
                if (!workbook.SheetNames || workbook.SheetNames.length === 0) {
                    throw new Error("Uploaded file does not contain valid sheets.");
                }
                
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX_utils.sheet_to_json(worksheet, { header: 1, raw: false });
                if (!jsonData || jsonData.length === 0) {
                    throw new Error("Uploaded file is empty or invalid.");
                }
                console.log("jsonData:", jsonData);
                const csvHeaders = jsonData[0] || [];
                const dateRegex = /^([1-9]|1[0-2])\/([1-9]|[12][0-9]|3[01])\/(\d{2})$/;
                const fileRows = jsonData.slice(1).filter((row) => row.length !== 0).map((row) => row.map( (cell, colIndex) => dateRegex.test(cell) ? dateTimeFormater(cell, "yyyy-mm-dd") : cell ));
                console.log("fileRows:", fileRows);
                setCsvHeaders(csvHeaders); 
                setFileData(fileRows); 
            } catch (error) {
                handleErrorMessage(error.message || "An error occurred while processing the file.");
                return false; 
            }
        };
        reader.readAsArrayBuffer(file);
        return true; 
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (processFile(file)) {
                setUploadedFile(file); 
            }
        } else {
            handleErrorMessage("No file selected.");
        }
    };

    const handleDroppedFiles = (files) => {
        const file = files[0];
        if (file) {
            if (processFile(file)) {
                setUploadedFile(file); 
            }
        } else {
            handleErrorMessage("No file dropped.");
        }
    };

    const handleRemoveFile = () => {
        setUploadedFile(null);
    };


    return (
        <Box
            sx={{
                padding: "24px",
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                maxWidth: isMobile ? "95%" : "800px",
                minWidth: isMobile ? "95%" : "800px",
                margin: "0 auto",
                overflowY: "auto",
            }}
        >
            {/* Header */}
            <Typography sx={{ fontSize: "20px", fontWeight: 700, color: "#0C1222", marginBottom: "16px" }}>
                Bulk Upload
            </Typography>

            {/* Step Section */}
            <Box sx={{ marginBottom: "16px" }}>
                <Typography sx={{ fontSize: "12px", fontWeight: 400, color: "#0C1222" }}>Step 1</Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: 600, color: "#0C1222" }}>Upload your .csv/.xlsx file</Typography>
                {fileData.length === 0 &&
                    <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#0C1222", marginTop: '4px' }}>
                        Upload a .xlsx/.csv file to create or update in bulk a set of units, tenancies, or both. <br/>
                        To upload units, it is necessary to have "Unit" and "Reference" columns. <br />
                        To attach a unit to a building or community, it is necessary to enter either 
                        "Building" or "Community" column and fill in it's name as described in the system. <br />
                        To upload tenancies, it is necessary to have "Tenant Full Name", "Move-In", and "Move-Out" columns. <br />
                        Please note that dates should be submitted in the following format: yyyy-mm-dd.
                    </Typography>
                }
            </Box>

            {/* Template File Section */}
            <Box
                sx={{
                    backgroundColor: "#F3F5F6",
                    padding: "16px",
                    paddingTop: "48px",
                    borderRadius: "8px",
                    marginBottom: "16px",
                    overflowX: "auto",
                    position: 'relative',
                }}
            >
                {/* Header Section */}
                <Box
                    sx={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        right: "10px",
                        // top: "160px",
                        // left: "70px",
                        // right: "70px",
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "8px",
                        zIndex: 10,
                        backgroundColor: "#F3F5F6",
                        padding: "8px",
                        borderRadius: "8px",
                    }}
                >
                    <Typography sx={{ fontSize: "14px", fontWeight: 600, color: "#0C1222" }}>
                        Template File
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "12px",
                            fontWeight: 700,
                            color: "#4F46E5",
                            cursor: "pointer",
                        }}
                        onClick={downloadTemplate}
                    >
                        Download
                    </Typography>
                </Box>

                {/* Table for File Data */}
                <Table
                    sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "8px",
                        tableLayout: "fixed",
                        // minWidth: "1400px",
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {(csvHeaders.length === 0 ? headers : csvHeaders).map((header, index) => (
                                <TableCell
                                    key={index}
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        color: "#0C1222",
                                        lineHeight: "15.6px",
                                        // whiteSpace: "nowrap",
                                        overflowWrap: "break-word",
                                        wordBreak: "break-word",
                                        width: "97px",
                                    }}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fileData.length > 0 ? (
                                fileData.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {(csvHeaders.length === 0 ? headers : csvHeaders).map((_, colIndex) => (
                                            <TableCell
                                                key={colIndex}
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: 400,
                                                    color: "#0C1222",
                                                    maxWidth: "400px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {row[colIndex] || "-"}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    {(csvHeaders.length === 0 ? headers : csvHeaders).map((_, colIndex) => (
                                        <TableCell
                                            key={colIndex}
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: 400,
                                                color: "#0C1222",
                                                maxWidth: "400px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {"-"}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </Box>

            {/* Drag and Drop Section */}
            <Box
                sx={{
                    border: "2px dashed #7F8594",
                    borderRadius: "8px",
                    padding: "32px",
                    textAlign: "center",
                    marginBottom: "16px",
                    backgroundColor: "#F9FAFB",
                    display: uploadedFile ? "flex" : "block", 
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "relative",
                }}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => e.preventDefault()}
                onDrop={(e) => {
                    e.preventDefault();
                    const files = Array.from(e.dataTransfer.files);
                    handleDroppedFiles(files);
                }}
            >
                {uploadedFile ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {/* Left Section: File details */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "16px",
                            }}
                        >
                            {/* File Icon */}
                            <Box
                                sx={{
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: "#F3F5F6",
                                    borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <UploadFileIcon sx={{ fontSize: "24px", color: "#0C1222" }} />
                            </Box>

                            {/* File Name and Status */}
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        color: "#0C1222",
                                        marginBottom: "4px",
                                    }}
                                >
                                    {uploadedFile.name}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        color: "#7F8594",
                                    }}
                                >
                                    Ready to be uploaded
                                </Typography>
                            </Box>
                        </Box>
                        
                        {/* Right Section: Change File */}
                        <Typography
                            sx={{
                                fontSize: "12px",
                                fontWeight: 700,
                                color: "#4F46E5",
                                cursor: "pointer",
                                textAlign: "right",
                            }}
                            onClick={handleRemoveFile}
                        >
                            Change File
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#0C1222",
                                marginBottom: "8px",
                            }}
                        >
                            Drag the .csv file to upload
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#0C1222",
                                marginBottom: "16px",
                            }}
                        >
                            Or
                        </Typography>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{
                                backgroundColor: "#F2F2F3",
                                color: "#0C1222",
                                fontWeight: 500,
                                fontSize: "14px",
                                textTransform: "none",
                            }}
                        >
                            Browse
                            <input type="file" accept=".csv, .xlsx" hidden onChange={handleFileUpload} />
                        </Button>
                    </>
                )}
            </Box>

            {/* Next Button */}
            <Button
                variant="contained"
                sx={{
                    backgroundColor: "#4F46E5",
                    color: "#FFFFFF",
                    fontWeight: 700,
                    fontSize: "14px",
                    textTransform: "none",
                    display: "block",
                    marginLeft: "auto",
                }}
                onClick={onNext}
                disabled={!uploadedFile}
            >
                Next
            </Button>
        </Box>
    );
};

export default StepOne;