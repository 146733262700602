import React from 'react'

const DateIcon = ({ fill = 'none', innerFill = "#C2C4C8", width = '18px', height = '18px', styleClass = "" }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33594 6.00065C1.33594 4.74332 1.33594 4.11532 1.7266 3.72465C2.11727 3.33398 2.74527 3.33398 4.0026 3.33398H12.0026C13.2599 3.33398 13.8879 3.33398 14.2786 3.72465C14.6693 4.11532 14.6693 4.74332 14.6693 6.00065C14.6693 6.31465 14.6693 6.47198 14.5719 6.56998C14.4739 6.66732 14.3159 6.66732 14.0026 6.66732H2.0026C1.6886 6.66732 1.53127 6.66732 1.43327 6.56998C1.33594 6.47198 1.33594 6.31398 1.33594 6.00065ZM1.33594 12.0007C1.33594 13.258 1.33594 13.886 1.7266 14.2767C2.11727 14.6673 2.74527 14.6673 4.0026 14.6673H12.0026C13.2599 14.6673 13.8879 14.6673 14.2786 14.2767C14.6693 13.886 14.6693 13.258 14.6693 12.0007V8.66732C14.6693 8.35332 14.6693 8.19598 14.5719 8.09798C14.4739 8.00065 14.3159 8.00065 14.0026 8.00065H2.0026C1.6886 8.00065 1.53127 8.00065 1.43327 8.09798C1.33594 8.19598 1.33594 8.35398 1.33594 8.66732V12.0007Z" fill="#918BEF"/>
            <path d="M4.67188 2V4M11.3385 2V4" stroke="#918BEF" strokeWidth="1.33333" strokeLinecap="round"/>
        </svg>
    );
};

export default DateIcon;