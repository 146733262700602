import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Box, Paper, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useHistory } from 'react-router-dom';
//hooks
import useDebounce from '../../../../hooks/useDebounce';
//slices
import { fetchSearchResults, setOpen, resetSearch, setIsMobileSearch } from '../../../../store/slices/globalSearchSlice';


const PhoneView = ({ 
    searchTerm, handleSearchChange, handleKeyDown, handleCloseMobileSearch, results, loading, 
    activeIndex, itemRefs, handleResultClick, highlightMatch 
}) => {
    return (
        <div className="flex flex-col h-full">
            <div className="flex items-center pb-4">
                <IconButton onClick={handleCloseMobileSearch}>
                    <ArrowLeftIcon className="h-6 w-6 text-gray-500" />
                </IconButton>
                <input
                    id="mobile-search-field"
                    name="search"
                    type="search"
                    placeholder="Search anything..."
                    className="block w-full h-10 px-4 bg-gray-100 focus:ring-0 text-sm"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown}
                    autoFocus
                />
            </div>
            <div className="flex-1 overflow-y-auto">
                {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                            <CircularProgress size={24} />
                        </Box>
                    ) : (
                        <List>
                            {results.map((result, index) => (
                                <ListItem
                                    button
                                    key={index}
                                    ref={(el) => (itemRefs.current[index] = el)}
                                    onClick={() => handleResultClick(result)}
                                    selected={index === activeIndex}
                                    sx={{
                                    backgroundColor: index === activeIndex ? '#f0f0f0' : 'inherit',
                                    }}
                                >
                                    <ListItemText primary={highlightMatch(result.subject, searchTerm)} />
                                </ListItem>
                            ))}
                        </List>
                    )
                }
            </div>
        </div>
    );
};
const DesktopView = ({ 
    searchTerm, handleSearchChange, handleFocus, handleKeyDown, results, loading, 
    open, activeIndex, itemRefs, handleResultClick, highlightMatch 
}) => {
    return (
        <Box sx={{ flexGrow: '1', margin: '20px auto' }}>
            <div className="flex h-10 w-full max-w-sm">
                <label htmlFor="search-field" className="sr-only">Search</label>
                <div className="relative bg-gray-100 lg:w-80 rounded-lg w-full">
                    <MagnifyingGlassIcon 
                        aria-hidden="true" 
                        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500 ml-2" />
                    <input
                        id="search-field"
                        name="search"
                        type="search"
                        placeholder="Search anything here"
                        className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 focus:ring-0 text-sm"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
            {open && results.length > 0 && (
                <Paper
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        zIndex: 1,
                        maxHeight: '350px',
                        overflowY: 'auto',
                        border: '1px solid #ddd',
                    }}
                >
                    {loading ? (
                            <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                                <CircularProgress size={24} />
                            </Box>
                        ) : (
                            <List>
                                {results.map((result, index) => (
                                    <ListItem
                                        button
                                        key={index}
                                        ref={(el) => (itemRefs.current[index] = el)}
                                        onClick={() => handleResultClick(result)}
                                        selected={index === activeIndex}
                                        sx={{
                                            backgroundColor: index === activeIndex ? '#f0f0f0' : 'inherit',
                                        }}
                                    >
                                        <ListItemText primary={highlightMatch(result.subject, searchTerm)} />
                                    </ListItem>
                                ))}
                            </List>
                        )
                    }
                </Paper>
            )}
        </Box>
    );
};

const SearchInput = () => {
    const dispatch = useDispatch();
    
    const [searchTerm, setSearchTerm] = useState('');
    const [activeIndex, setActiveIndex] = useState(-1);
    const searchRef = useRef();
    const itemRefs = useRef([]);
    
    const { isMobile } = useSelector((state) => state.config);
    const { results, loading, open, isMobileSearch } = useSelector((state) => state.globalSearch); 
    const debouncedSearchTerm = useDebounce(searchTerm, 200);
    
    const history = useHistory();
    
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setActiveIndex(-1);
    };
    
    const handleFocus = () => {
        if (searchTerm.trim() !== '') {
            dispatch(setOpen(true));
        }
        if (isMobile) {
            dispatch(setIsMobileSearch(true));  
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            setActiveIndex((prevIndex) => {
                const newIndex = Math.min(prevIndex + 1, results.length - 1);
                if (itemRefs.current[newIndex]) {
                    itemRefs.current[newIndex].scrollIntoView({ block: 'nearest' });
                }
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            setActiveIndex((prevIndex) => {
                const newIndex = Math.max(prevIndex - 1, 0);
                if (itemRefs.current[newIndex]) {
                    itemRefs.current[newIndex].scrollIntoView({ block: 'nearest' });
                }
                return newIndex;
            });
        } else if (e.key === 'Enter') {
            if (activeIndex === -1) {
                history.push(`/units#subject=${encodeURIComponent(searchTerm)}`);
                setSearchTerm('');
                dispatch(setOpen(false));
                dispatch(resetSearch());
            } else if (activeIndex !== -1) {
                handleResultClick(results[activeIndex]);
            }
        }
    };

    const handleResultClick = (result) => {
        dispatch(setOpen(false));
        history.push(`/units#subject=${encodeURIComponent(result.subject)}`);
        setSearchTerm('');
        setActiveIndex(-1);
        dispatch(resetSearch());
        dispatch(setIsMobileSearch(false));  
    };
    
    const highlightMatch = (text, query) => {
        if (!query) return text;
        const regex = new RegExp(`(${query})`, 'gi');
        const parts = text.split(regex);
        return parts.map((part, index) =>
            regex.test(part) ? <span key={index} style={{ color: 'rgb(129 140 248)' }}>{part}</span> : part
        );
    };
    
    useEffect(() => {
        if (debouncedSearchTerm.trim() !== '') {
            dispatch(fetchSearchResults(debouncedSearchTerm));
        } else {
            dispatch(setOpen(false));
        }
    }, [debouncedSearchTerm, dispatch]);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                dispatch(setOpen(false));
                setActiveIndex(-1);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch, searchRef]);
    
    const handleCloseMobileSearch = () => {
        dispatch(setIsMobileSearch(false));
        setSearchTerm('');
        dispatch(resetSearch());
        dispatch(setOpen(false));
    };
    
    
    return (
        <div 
            ref={searchRef}
            className={`${isMobileSearch ? 'fixed top-0 left-0 w-screen h-screen bg-white z-[1000] p-4 overflow-y-auto' : 'relative'}`} 
        >
            {isMobileSearch ? (
                    <PhoneView
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                        handleKeyDown={handleKeyDown}
                        handleCloseMobileSearch={handleCloseMobileSearch}
                        results={results}
                        loading={loading}
                        activeIndex={activeIndex}
                        itemRefs={itemRefs}
                        handleResultClick={handleResultClick}
                        highlightMatch={highlightMatch} />
                ) : (
                    <DesktopView
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                        handleFocus={handleFocus}
                        handleKeyDown={handleKeyDown}
                        results={results}
                        loading={loading}
                        open={open}
                        activeIndex={activeIndex}
                        itemRefs={itemRefs}
                        handleResultClick={handleResultClick}
                        highlightMatch={highlightMatch} />
                )
            }
        </div>
    );
};

export default SearchInput;