import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//utils
import axios from '../../axios/axios';
//constants
import { CategoryFilter } from '../../constants';
import { para_be } from '../../config';


// Thunk to fetch table data
export const fetchData = createAsyncThunk(
    'table/fetchTableData',
    async ({ lowerLimit, upperLimit, filters, searchValue, sortDirection, sortColumn }, { rejectWithValue }) => {
        const queryString = `upperLimit=${upperLimit}&lowerLimit=${lowerLimit}&filters=${encodeURIComponent(JSON.stringify(filters))}&searchValue=${searchValue}&sortDirection=${sortDirection}&sortColumn=${sortColumn}`;
        try {
            const tableDataResponse = await axios.get(`${para_be}/dashboard/search?${queryString}`, { withCredentials: true });
            if (tableDataResponse.data.status >= 400) {
                return rejectWithValue(tableDataResponse.data.msg);
            }
            return {
                data: tableDataResponse.data, 
                lowerLimit, 
                upperLimit, 
                filters, 
                searchValue,
                sortDirection,
                sortColumn,
            };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

export const fetchDataTotal = createAsyncThunk(
    'table/fetchDataTotal',
    async ({ k, filters, searchValue, sortDirection, sortColumn }, { rejectWithValue }) => {
        const queryString = `upperLimit=${1}&lowerLimit=${0}&filters=${encodeURIComponent(JSON.stringify(filters))}&searchValue=${searchValue}&sortDirection=${sortDirection}&sortColumn=${sortColumn}`;
        try {
            const tableDataResponse = await axios.get(`${para_be}/dashboard/search?${queryString}`, { withCredentials: true });
            if (tableDataResponse.data.status >= 400) {
                return rejectWithValue(tableDataResponse.data.msg);
            }
            return {
                total: tableDataResponse.data.data.maxRows[0][0],
                key: k
            };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

const initialState = {
    data: [],         // Holds table data
    page: 1,
    pageSize: 20,
    totalItems: 0,
    loading: false,  
    btnLoading: false,
    error: null,      
    lowerLimit: 0,
    upperLimit: 20,
    categoryFilter: CategoryFilter.MOVE_OUT,
    filters: {},
    sortDirection: false,
    sortColumn: 0,
    searchValue: '',
    selectedRowInTable: {
        rid: '',
        pid: '',
        subject: '',
        ext_ref: '',
        videoLink: '',
    },
    modals: {
        videoModal: false,
        uploaderModal: false,
        allReportsModal: false,
        allTenanciesModal: false,
        updateUnitsAddressModal: false,
        updateUnitReferenceModal: false,
        deleteUnitModal: false,
        approveModal: false,
        rejectModal: false,
    },
    totals: {
        scansNotReviewed: 0,
        scansAccepted: 0,
        scansRejected: 0,
    }
};

const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        setData(state, action) {
            state.data = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
        },
        setSearchValue(state, action) {
            state.searchValue = action.payload;
        },
        setCategoryFilter(state, action) {
            state.categoryFilter = action.payload;
        },
        setLowerLimit(state, action) {
            state.lowerLimit = action.payload;
        },
        setUpperLimit(state, action) {
            state.upperLimit = action.payload;
        },
        setBtnLoading(state, action) {
            state.btnLoading = action.payload;
        },
        resetState(state, action) {
            const resetValue = {...initialState, ...(action.payload || {})};
            for (let k in resetValue) {
                state[k] = resetValue[k];
            }
        },
        
        // Centralized modal management
        openModal(state, action) {
            const { 
                modalName, pid, subject, ext_ref, videoLink, rid, jid, journeyName, 
                sid, assigned_to, due_range, notices, inspectionType, shareLink
            } = action.payload;
            state.modals[modalName] = true;
            if (pid) state.selectedRowInTable.pid = pid;
            if (subject) state.selectedRowInTable.subject = subject;
            if (ext_ref) state.selectedRowInTable.ext_ref = ext_ref;
            if (videoLink) state.selectedRowInTable.videoLink = videoLink;
            if (rid) state.selectedRowInTable.rid = rid;
            if (jid) state.selectedRowInTable.jid = jid;
            if (sid) state.selectedRowInTable.sid = sid;
            if (journeyName) state.selectedRowInTable.journeyName = journeyName;
            if (assigned_to) state.selectedRowInTable.assigned_to = assigned_to;
            if (due_range) state.selectedRowInTable.due_range = due_range;
            if (notices) state.selectedRowInTable.notices = notices;
            if (inspectionType) state.selectedRowInTable.inspectionType = inspectionType;
            if (shareLink) state.selectedRowInTable.shareLink = shareLink;
        },
        closeModal(state, action) {
            const modalName = action.payload;
            state.modals[modalName] = false;
            state.selectedRowInTable.pid = '';
            state.selectedRowInTable.subject = '';
            state.selectedRowInTable.ext_ref = '';
            state.selectedRowInTable.videoLink = '';
            state.selectedRowInTable.rid = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.rows;
                state.totalItems = action.payload.data.data.maxRows[0][0];
                state.loading = false;
                state.error = null;
                // Update the state with the parameters used in the request
                state.lowerLimit = action.payload.lowerLimit;
                state.upperLimit = action.payload.upperLimit;
                state.filters = action.payload.filters;
                state.searchValue = action.payload.searchValue;
                state.sortDirection = action.payload.sortDirection;
                state.sortColumn = action.payload.sortColumn;
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong while fetching table data';
            })
            .addCase(fetchDataTotal.fulfilled, (state, action) => {
                state.totals[action.payload.key] = action.payload.total;
            });
    }
});


export const {
    setData,
    setPage,
    setCategoryFilter,
    setFilters,
    setSearchValue,
    setLowerLimit,
    setUpperLimit,
    setBtnLoading,
    resetState,
    openModal,
    closeModal,
} = tableSlice.actions;
export default tableSlice.reducer;