import { Document, Page, Text, View, StyleSheet, Font, Image, Link } from '@react-pdf/renderer';

// assets
import oswaldFont from "../../../assets/fonts/oswald_font.ttf";
import robotoFont from "../../../assets/fonts/roboto_regular.ttf";
import { dateTimeFormater } from '../../../utils/date_utils';
import { DATE_FORMAT } from '../../../constants';

try {
    Font.register({
        family: 'Oswald',
        // src: oswaldFont,
        src: '/assets/fonts/oswald_font.ttf',
        format: 'truetype'
    });
} catch (error) {
    console.error("Error registering font 'Oswald':", error);
}
try {
    Font.register({
        family: 'Roboto',
        src: '/assets/fonts/roboto_regular.ttf',
        format: 'truetype',
        fontWeight: 400
    });
} catch (error) {
    console.error("Error registering font 'Roboto':", error);
}
try {
    Font.register({
        family: 'Roboto-Bold',
        src: '/assets/fonts/roboto_regular.ttf',
        format: 'truetype',
        fontWeight: 700
    });
} catch (error) {
    console.error("Error registering font 'Roboto-Bold':", error);
}

const styles = StyleSheet.create({
    body: {
        padding: 40,
        fontFamily: 'Roboto',
        backgroundColor: '#F7F8FA',
    },
    header: {
        backgroundColor: '#4F46E5',
        padding: 25,
        borderRadius: 8,
        textAlign: 'center',
        marginBottom: 30,
        color: '#FFF',
    },
    logoView: {
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerText: {
        fontSize: 26,
        fontWeight: 'bold',
        fontFamily: 'Oswald',
    },
    subtitle: {
        fontSize: 14,
        fontFamily: 'Roboto',
        marginTop: 5,
        color: '#E5E5FF',
    },
    reportDate: {
        fontSize: 12,
        color: '#EEE',
        marginTop: 10,
    },
    sectionHeader: {
        fontSize: 18,
        fontFamily: 'Roboto-Bold',
        color: '#333',
        marginBottom: 10,
        paddingBottom: 4,
        borderBottom: '2px solid #4F46E5',
    },
    text: {
        fontSize: 12,
        color: '#333',
        lineHeight: 1.5,
        marginBottom: 4,
    },
    productContainer: {
        marginBottom: 25,
        backgroundColor: '#FFF',
        padding: 15,
        borderRadius: 8,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        border: '1px solid #E0E0E0',
    },
    productTitle: {
        fontWeight: 'bold',
        marginBottom: 8,
        fontSize: 14,
        color: '#4F46E5',
    },
    imageContainer: {
        marginVertical: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: 180,
        height: 180,
        borderRadius: 8,
        border: '1px solid #CCC',
        marginHorizontal: 10,
    },
    noteContainer: {
        padding: 15,
        borderRadius: 8,
        backgroundColor: '#F9F9FB',
        border: '1px solid #E0E0E0',
        marginBottom: 20,
    },
    chargeDetails: {
        padding: 10,
        borderRadius: 8,
        backgroundColor: '#F2F2FF',
        marginBottom: 20,
    },
    footer: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 10,
        color: '#555',
        fontFamily: 'Roboto',
    },
    priceText: {
        fontSize: 12,
        color: '#2C3E50',
        fontWeight: 'bold',
        marginBottom: 4,
    },
});

export default function ReportPDF({ 
    clientLogo, subject='Untitled Report', report_datetime='N/A', customText='', reportItems={}, chargeAmount='', selectedCurrency='', inspectionType=""
}) {
    // const products = reportItems?.inventory || [];
    const products = Object.keys(reportItems).reduce((acc, item) => [...acc, ...reportItems[item]], []);
    const productsPerPage = 2;

    const chunkedProducts = [];
    for (let i = 0; i < products.length; i += productsPerPage) {
        chunkedProducts.push(products.slice(i, i + productsPerPage));
    }

    const inspectionTitle = () => {
        if (inspectionType === 'move_in' || inspectionType === "checkin") return 'Move In';
        if (inspectionType === 'move_out' || inspectionType === "checkout") return 'Move Out';
        if (inspectionType === 'semi_annual') return 'Semi-Annual';
        if (inspectionType === 'annual') return 'Annual';
        if (inspectionType === 'turn') return 'Turn';
        return 'Move Out';
    }


    return (
        <Document 
        author="Paraspot AI"
        title={`${subject} ${inspectionTitle()} Report`}
        subject={`${inspectionTitle()} Report of ${subject} From ${report_datetime}`}
        language="en">
            <Page style={styles.body}>
                <View style={styles.logoView}>
                    {/* {clientLogo && 
                        <Image 
                            src={{ 
                                uri: clientLogo, 
                                method: "GET", 
                                headers: { "Cache-Control": "no-cache" }
                            }} 
                            style={{ width: 70, height: 70, marginBottom: 10 }} 
                        />
                    } */}
                    {clientLogo && <Image src={clientLogo} style={{ width: 70, height: 70, marginBottom: 10 }} />}
                </View>
                <View style={styles.header}>
                    {/* {clientLogo && 
                        <Image 
                            src={{ uri: clientLogo, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} 
                            style={{ width: 50, height: 50, marginBottom: 10 }} 
                        />
                    } */}
                    <Text style={styles.headerText}>{subject}</Text>
                    <Text style={styles.subtitle}>Detailed {inspectionTitle()} Report</Text>
                    <Text style={styles.reportDate}>Report Date: {report_datetime}</Text>
                </View>
                
                {chargeAmount > 0 && selectedCurrency && (
                    <View style={styles.chargeDetails}>
                        <Text style={styles.sectionHeader}>Charge Details</Text>
                        <Text style={styles.text}>
                            Total Charge: {selectedCurrency === 'eur' ? '€' : '$'}{Number(chargeAmount).toLocaleString('en-US')}
                        </Text>
                    </View>
                )}

                {customText !== '' && (
                    <View style={styles.noteContainer}>
                        <Text style={styles.sectionHeader}>Note</Text>
                        <Text style={styles.text}>{customText}</Text>
                    </View>
                )}

                <Text style={styles.sectionHeader}>Disclaimer</Text>
                <Text style={styles.text}>
                    This Document has been automatically generated by Paraspot AI.
                    This document serves as a record of the property's condition at the time of inspection and is provided for informational purposes only.
                    Paraspot AI is not liable for the content, edits, or outcomes based on this document.
                </Text>
            </Page>
            
            {chunkedProducts.map((pageItems, pageIndex) => (
                <Page key={pageIndex} style={styles.body}>
                    <Text style={styles.sectionHeader}>Items - Page {pageIndex + 1}</Text>
                    {pageItems.map((item, index) => (
                        <View key={index} style={styles.productContainer}>
                            <Text style={styles.productTitle}>{item.subject || 'No Subject'}</Text>
                            {item.description !== '' && (
                                <Text style={styles.text}>
                                    {item.description || 'No Description Provided'}
                                </Text>)
                            }
                            
                            {item.price && parseFloat(item.price) > 0 && (
                                <Text style={styles.priceText}>
                                    Cost: {selectedCurrency === 'eur' ? '€' : '$'}{Number(item.price).toLocaleString('en-US')}
                                </Text>
                            )}
                            
                            {item.before_date && (
                                <Text style={[styles.text, { color: '#888', fontSize: 10 }]}>
                                    (Before [{dateTimeFormater(item.before_date, DATE_FORMAT)}]: Left, After [{report_datetime.split(" ")[0]}]: Right)
                                </Text>
                            )}
                            
                            <View style={styles.imageContainer}>
                                {/* <Link src={item.img_before} style={styles.image}> */}
                                {item.img_before && typeof item.img_before === 'string' && (
                                    <Link src={item.img_before}>
                                        <Image src={item.img_before} style={styles.image} />
                                    </Link>
                                )}
                                {/* <Link src={item.img} style={styles.image}> */}
                                {item.img && typeof item.img === 'string' && (
                                    <Link src={item.img}>
                                        <Image src={item.img} style={styles.image} />
                                    </Link>
                                )}
                            </View>

                            <Text style={[styles.text, { color: '#888', fontSize: 7 }]}>
                                You may click on the images above to view them in full resolution in a new tab.
                            </Text>
                        </View>
                    ))}
                    <Text
                        style={styles.footer}
                        render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                        fixed
                    />
                </Page>
            ))}
        </Document>
    );
}
