import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { XMarkIcon } from '@heroicons/react/24/outline';

const BaseModal = ({ width, open, onClose, children, minWidth = 400, maxWidth = 400 , maxHeight = '90%' }) => {
  
   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: minWidth, 
        maxWidth: maxWidth, 
        maxHeight: maxHeight,  
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto', 
    };

  return (
    <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // disableScrollLock
    >
        <Box sx={style}>
            <XMarkIcon className="w-6 h-6 absolute top-2 right-2 cursor-pointer text-gray-700" onClick={onClose} />
            {children}
        </Box>
    </Modal>
  );
};

export default BaseModal;