import React from 'react';
import { useDispatch } from 'react-redux';
//mui
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Typography } from '@mui/material';
//slice
import { setPreviewMode, setLinkModal } from '../../../../store/slices/reportsSlice';
//assets
import LogoParaspot from '../../../../assets/logo-paraspot.png';


const PreviewHeaderControls = () => {
    const dispatch = useDispatch();
    
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem',
                    paddingTop:'1.5rem',
                }}
            >
                {/* Circular Back Button */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Button
                        sx={{
                            backgroundColor: '#F2F2F3',
                            color: '#0C1222',
                            width: '40px', 
                            height: '40px',
                            minWidth: 0, 
                            borderRadius: '50%', 
                            border: '1px solid #E0E0E0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                backgroundColor: '#E0E0E0',
                            }
                        }}
                        onClick={() => dispatch(setPreviewMode(false))}
                    >
                        <ArrowBackIcon />
                    </Button>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: '20px',
                            color: '#0C1222',
                        }}
                    >
                        Preview
                    </Typography>
                </Box>
                
                {/* Right div: Share Button */}
                <Box>
                    <Button
                        sx={{
                            backgroundColor: '#4F46E5',
                            color: '#FFFFFF',
                            fontSize: '12px',
                            fontWeight: 700,
                            textTransform: 'none',
                            borderRadius: '4px',
                            padding: '6px 12px',
                            '&:hover': {
                                backgroundColor: '#3C3EB8',
                            }
                        }}
                        onClick={() => dispatch(setLinkModal({ open: true }))}
                    >
                        Share
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    ml:4,
                    mt:4
                }}
            >
                <img alt="Your Company" src={LogoParaspot} className="h-7 w-27"  />
            </Box>
        </>
    );
};

export default PreviewHeaderControls;