import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//mui
import { Box, Typography, IconButton, TextField, Button } from '@mui/material';
//components
import CustomTooltip from '../../../../../../components/CustomTooltip';
//assets
import SquarePen from '../../../../icons/squarePen';
import MailIcon from '../../../../icons/mailIcon';
import PhoneIcon from '../../../../icons/phoneicon';
import BuildingUp from '../../../../icons/buildingUp';
import DollarSign from '../../../../icons/dollarSign';
import { para_be } from '../../../../../../config';
import { postReqOptBuilder } from '../../../../../../utils/main_utils';
import CustomButton from '../../../../../../components/buttons/CustomButton';
import CircularLoader from '../../../../../../components/loaders/CircularLoader';
import { dateTimeFormater } from '../../../../../../utils/date_utils';
import { DATE_FORMAT } from '../../../../../../constants';


const GuestDetails = () => {
    const dispatch = useDispatch();
    const { guestData, report_id, previewMode } = useSelector((state) => state.reports);
    const { name, email, mobile, "check-in": checkin, checkout, deposit } = guestData;
    
    const [editMode, setEditMode] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [formData, setFormData] = useState({ name, email, mobile, checkin, checkout, deposit });
    
    useEffect(() => {
        setFormData({ name, email, mobile, checkin, checkout, deposit });
    }, [guestData]);
    
    const handleEditToggle = () => {
        setEditMode((prev) => !prev);
        // if (editMode) saveEdit();
    };
    
    const saveEdit = () => {
        console.log('Saving changes:', formData);
        setLoadingSave(true);
        fetch(para_be + "/reports/update_guest_data", postReqOptBuilder(
            {
                report_id, 
                guest_data: {
                    name: formData.name, 
                    email: formData.email, 
                    mobile: formData.mobile, 
                    deposit: formData.deposit, 
                    "check-in": formData.checkin, 
                    checkout: formData.checkout
                }
            }
        ))
        .then((response) => response.json())
        .then((response) => {
            setLoadingSave(false);
            setEditMode(false);
        })
    };
    
    const handleChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };
    
    const renderField = useCallback((IconComponent, field, title, placeholder, showBorder) => (
        <Box className="mr-2 mt-3 pr-2" key={field} style={showBorder ? { borderRight: '1px solid #D9D9D9' } : {}}>
            <div className="flex space-x-2 items-center" title={title}>
                <IconComponent styleClass="mt-0.5" />
                {editMode ? (
                        <TextField
                            variant="standard"
                            size="small"
                            value={formData[field]}
                            onChange={(e) => handleChange(field, e.target.value)}
                            inputProps={{ style: { fontSize: '12px' } }}
                            placeholder={placeholder}
                        />
                    ) : (
                        <Typography variant="body2" className="text-gray-500 text-xs">
                            {field === "checkin" || field === "checkout" ? dateTimeFormater(formData[field], DATE_FORMAT) : field === "deposit" ? Number(formData[field]).toLocaleString('en-US') : formData[field]}
                        </Typography>
                    )
                }
            </div>
        </Box>
    ), [editMode, formData]);
    
    
    return (
        <div className="pl-2">
            <Box className="flex items-center">
                <Typography
                    variant="h6"
                    sx={{
                        color: '#0C1222',
                        fontSize: '12px',
                        fontWeight: 600
                    }}
                >
                    GUEST DETAILS
                </Typography>
                
                {(!previewMode && !editMode) && (
                    <CustomTooltip title="Click to edit guest details">
                        <IconButton size="small" className="text-[#0C1222]" onClick={handleEditToggle}>
                            <SquarePen />
                        </IconButton>
                    </CustomTooltip>
                )}
            </Box>
            
            <Box className="flex items-center">
                {editMode ?
                    <TextField
                        variant="standard"
                        size="small"
                        value={formData.name}
                        onChange={(e) => handleChange("name", e.target.value)}
                        inputProps={{ style: { fontSize: '14px', fontWeight: 600 } }}
                        placeholder="Enter name" />
                    :
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#0C1222',
                            fontSize: '14px',
                            fontWeight: 600
                        }}
                    >
                        {formData.name ? formData.name : "N/A"}
                    </Typography>
                }
            </Box>
            
            <Box className="flex flex-wrap">
                {renderField(MailIcon, "email", "Email Address", "Enter email", true)}
                {renderField(PhoneIcon, "mobile", "Mobile Number", "Enter phone", true)}
                {renderField(BuildingUp, "checkin", "Move-In Date", "Enter move-in date", true)}
                {renderField(BuildingUp, "checkout", "Move-Out Date", "Enter move-out date", true)}
                {renderField(DollarSign, "deposit", "Deposit Amount", "Enter deposit amount")}
            </Box>

            {editMode && (
                <div className='flex mt-4'>
                    <Button
                        size="small"
                        onClick={handleEditToggle}
                        sx={{
                            backgroundColor: '#F2F2F3',
                            color: '#0C1222',
                            fontSize: '12px',
                            fontWeight: 600,
                            marginRight: '6px',
                            textTransform: 'none',
                            borderRadius: '4px',
                            padding: '2px 6px',
                            border: '1px solid #E0E0E0',
                            '&:hover': {
                                backgroundColor: '#E0E0E0',
                            }
                        }}
                    >
                        {loadingSave ? <CircularLoader size={24} /> : "Cancel"}
                    </Button>
                    <Button
                        size="small"
                        onClick={saveEdit}
                        sx={{
                            backgroundColor: '#4F46E5',
                            color: '#FFFFFF',
                            fontSize: '12px',
                            fontWeight: 600,
                            textTransform: 'none',
                            borderRadius: '4px',
                            padding: '2px 6px',
                            '&:hover': {
                                backgroundColor: '#3C3EB8',
                            },
                        }}
                    >
                        {loadingSave ? <CircularLoader size={24} /> : "Save"}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default GuestDetails;