import React from 'react';
import { Button, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { PROPERTY_ROLE } from '../../../../../../constants';

const CommentSection = ({ currentCommentMode, item, handleDescriptionChange, handlePriceChange, toggleCommentMode }) => {

    const {
        finishExportMode, editExportMode, itemMetadata, exportReport, previewMode, tabView
    } = useSelector((state) => state.reports);

    if (!editExportMode && !previewMode) return null;

    const itemData = itemMetadata?.[tabView]?.[item.object_id] || { description: '', price: '' };
    const { propertyRole } = exportReport;


    return (
        <>
            <Button
                onClick={() => toggleCommentMode(item.object_id)}
                sx={{
                    backgroundColor: '#FFFFFF',
                    color: '#4F46E5',
                    fontSize: '12px',
                    fontWeight: 700,
                    textTransform: 'none',
                    width: '100px',
                    paddingLeft: '0px',
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    '&:hover': {
                        backgroundColor: '#FFFFFF',
                    },
                }}
            >
                {currentCommentMode}
            </Button>

            {currentCommentMode === 'Edit Comment' && (
                <>
                    <TextField
                        label="Description"
                        value={itemData.description}
                        onChange={(e) => handleDescriptionChange(item.object_id, e)}
                        placeholder='Add a description for this item'
                        fullWidth
                        multiline
                        rows={2}
                        variant='outlined'
                        sx={{
                            mt: 1,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#E0E0E0',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#BDBDBD',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#4F46E5',
                                },
                                '& .MuiInputBase-input': {
                                    fontSize: '14px',
                                },
                            },
                        }}
                    />
                    {propertyRole === PROPERTY_ROLE.RESIDENT &&
                        <TextField
                            label="Cost"
                            type="number"
                            value={itemData.price}
                            onChange={(e) => handlePriceChange(item.object_id, e)}
                            placeholder="Enter cost for this item"
                            fullWidth
                            variant='outlined'
                            sx={{
                                mt: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#E0E0E0',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#BDBDBD',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#4F46E5',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    fontSize: '14px',
                                    padding: '10px 14px',
                                },
                                '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
                                    fontSize: '14px',
                                    marginTop: '-6px',
                                }
                            }}
                        />
                    }
                </>
            )}
        </>
    )
}

export default CommentSection;