import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const ArrowButton = ({ direction, onClick, isVisible }) => {
    if (!isVisible) return null;
    
    const isLeft = direction === 'left';
    const icon = isLeft ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />;
    const position = isLeft ? { left: 16 } : { right: 16 };
    
    
    return (
        <IconButton
            onClick={onClick}
            sx={{
                position: 'fixed',
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'white',             
                backgroundColor: '#00000080',
                borderRadius: '50%',   
                zIndex: 2,
                ...position,
                '&:hover': {
                    backgroundColor: '#000000c0',  
                },
            }}
        >
            {icon}
        </IconButton>
    );
};

export default ArrowButton;