import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
//mui
import { Box, Backdrop, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
//components
import SnackBar from '../../components/SnackBar';
import ExportControls from './components/ExportControls';
import ViewReportHistory from './components/ViewReportHistory';
import PreviewHeaderControls from './components/PreviewHeaderControls';
import TenantMessage from './components/TenantMessage';
import BackToViewMode from './components/BackToViewMode';
import ThreeDotsLoder from '../../components/loaders/ThreeDotsLoader';
import LinkModal from './components/modals/LinkModal';
import LimitModal from './components/modals/LimitModal';
import ReportDetails from './components/ReportDetails';
import ImageViewerModal from './components/modals/ImageViewerModal';
//slices
import { fetchReportsData, resetEditMode, setLinkModal, checkIsReportPurchased, setIsLocked, setSelectedCategories } from '../../store/slices/reportsSlice';
import { fetchTermUnitsInspections, postLogEntry } from '../../store/slices/activitySlice';
import { resetActivity } from '../../store/slices/activitySlice';
import { resetClientLimit, fetchLimitsData } from '../../store/slices/clientLimitsSlice';
import { fetchEndUserData } from '../../store/slices/configSlice';
//hooks
import useReportSharedLogic from './hooks/useReportSharedLogic';
//constants
import { ReportsCategory } from '../../constants';


const Reports = () => {
    const { rid } = useParams();
    
    const dispatch = useDispatch();
    const {
        pid,
        date,
        header,
        inspectionType,
        reportData,
        filteredData,
        selectedImageIndex,
        loading,
        hasBaseline,
        editExportMode,
        previewMode,
        exportReport,
        linkModalOpen,
        isLocked,
        fetchExportReportLoding
    } = useSelector((state) => state.reports);
    const endUserData = useSelector(state => state.config.endUserData);

    const { customText } = exportReport;

    const { data } = useSelector((state) => state.clientLimits);
    const { termUnitsInspections, loading: activityLoading } = useSelector((state) => state.activity);
    const [showLimitPopup, setShowLimitPopup] = useState(false);

    const { year_inspections_in_unit } = data ?? { year_inspections_in_unit: 0 };

    const {
        tabIndex,
        notifState,
        handleNotifState,
        handleChangeTabIndex,
        renderTabContent,
    } = useReportSharedLogic();


    useEffect(() => {
        dispatch(postLogEntry( 
            { 
                activityID: rid, activityType: 'report', ip: endUserData?.ip, 
                action: {action: "view", target: "report"} 
            }
        ));
    }, [dispatch, rid, endUserData]);

    useEffect(() => {
        dispatch(fetchReportsData({ rid }));
        dispatch(fetchLimitsData());
    }, [dispatch, rid]);

    // useEffect(() => {
    //     if (pid) {
    //       dispatch(fetchTermUnitsInspections({ pid }));
    //     }
    // }, [dispatch, pid]);
    
    useEffect(() => {
        dispatch(fetchEndUserData());

        return () => {
            dispatch(resetEditMode());
            dispatch(setSelectedCategories(null));
            dispatch(resetActivity());
            dispatch(resetClientLimit());
        };
    }, [dispatch]);

    // useEffect(() => {
    //     if (!(loading || activityLoading)) {
    //         if (termUnitsInspections === 'true') {
    //             setShowLimitPopup(false); // Case 1: "true" -> Disable popup
    //         } else if (termUnitsInspections === 'false') {
    //             setShowLimitPopup(true); // Case 2: "false" -> Enable popup
    //         } else if (termUnitsInspections >= year_inspections_in_unit) {
    //             setShowLimitPopup(true); // Case 3: Number check -> Enable popup if condition met
    //         }
    //     }
    // }, [termUnitsInspections, year_inspections_in_unit, activityLoading, loading]);

    // useEffect(() => {
    //     dispatch(checkIsReportPurchased({ rid }));
    // }, [dispatch, rid]);

    const inspectionTitle = () => {
        if (inspectionType === 'move_in' || inspectionType === "checkin") return 'Move In';
        if (inspectionType === 'move_out' || inspectionType === "checkout") return 'Move Out';
        if (inspectionType === 'semi_annual') return 'Semi-Annual';
        if (inspectionType === 'annual') return 'Annual';
        if (inspectionType === 'turn') return 'Turn';
        return 'Move Out';
    }

    useEffect(() => {
        return () => {
            dispatch(setIsLocked(false));
        };
    }, [dispatch]);


    return (
        <>
            <Helmet>
                <title>Paraspot | {inspectionTitle()} Inspection Report - {header}</title>
                <meta name="description" content={`${inspectionTitle()} inspection report of the unit ${header} from ${date}`}/>
                <meta property="og:title" content={`Paraspot | ${inspectionTitle()} Report - ${header}`}/>
                <meta property="og:description" content={`${inspectionTitle()} report of the unit ${header} from ${date}`}/>
            </Helmet>
            <LinkModal open={linkModalOpen.open} export_id={linkModalOpen.export_id} handleClose={() => dispatch(setLinkModal({ open: false }))} />
            {/* LimitModal */}
            <LimitModal open={showLimitPopup && isLocked} rid={rid} />
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading || activityLoading} />

            {editExportMode && !previewMode && <BackToViewMode />}
            {previewMode && <PreviewHeaderControls />}

            <div className="flex gap-4 flex-col p-6">
                {/* View and Guest Info Section */}
                <ReportDetails rid={rid} handleNotifState={handleNotifState} />

                {/* Tabs and Export Controls Section */}
                <div className='flex flex-col rounded-lg shadow-md w-full p-4'>
                    {fetchExportReportLoding ? (
                            <ThreeDotsLoder
                                height="40"
                                width="40"
                                color="blue"
                                radius="5"
                                wrapperStyle={{ margin: 'auto' }}
                                wrapperClass="custom-loader-class"
                            />
                        ) : (
                            <TabContext value={tabIndex}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <div 
                                        className={`flex ${!editExportMode ? 'flex-row items-center' : 'flex-col-reverse'} pb-4`}
                                        style={{ justifyContent: editExportMode ? '' : 'space-between' }}
                                    >
                                        <TabList 
                                            onChange={handleChangeTabIndex}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            TabIndicatorProps={{
                                                style: {
                                                    backgroundColor: '#4F46E5'
                                                }
                                            }}
                                            sx={{
                                                '& .MuiTabs-root': {
                                                    color: '#5C6270',
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                },
                                                '& .Mui-selected': {
                                                    color: '#4F46E5 !important'
                                                }
                                            }}
                                        >
                                            <Tab label={`Inventory ${previewMode ? '' : ('(' + (reportData?.inventorySize ?? 0) + ')')}`} sx={{ textTransform: 'capitalize' }} value="1" />
                                            <Tab label={`Damages ${previewMode ? '' : ('(' + (reportData?.defectsSize ?? 0) + ')')}`} sx={{ textTransform: 'capitalize' }} value="2" />
                                            {hasBaseline && <Tab label={`Organization ${previewMode ? '' : ('(' + (reportData?.organizationSize ?? 0) + ')')}`} sx={{ textTransform: 'capitalize' }} value="3" />}
                                        </TabList>

                                        {!previewMode ? (
                                                <div className='flex'>
                                                    {/* Export Button or Controls */}
                                                    {!editExportMode ? (
                                                            <ViewReportHistory handleNotifState={handleNotifState} rid={rid} />
                                                        ) : (
                                                            <ExportControls handleNotifState={handleNotifState} rid={rid} />
                                                        )
                                                    }
                                                </div>
                                            ) : (
                                                <TenantMessage customText={customText} />
                                            )
                                        }
                                    </div> 
                                </Box>

                                <TabPanel value="1">{renderTabContent(ReportsCategory.INVENTORY)}</TabPanel>
                                <TabPanel value="2">{renderTabContent(ReportsCategory.DEFECTS)}</TabPanel>
                                <TabPanel value="3">{renderTabContent(ReportsCategory.ORGANIZATION)}</TabPanel>
                                
                                {/* Image Viewer Modal */}
                                {selectedImageIndex !== null && (
                                    <ImageViewerModal
                                        assetItems={filteredData}
                                    />
                                )}
                            </TabContext>
                        )
                    }
                </div>
            </div>

            {notifState && (
                <SnackBar
                    open={!!notifState}
                    handleClose={() => handleNotifState(null)}
                    severity={notifState.type}
                    message={notifState.text}
                    duration={notifState?.duration ? notifState.duration : 6000}
                />
            )}
        </>
    )
};

export default Reports;
