import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
//components
import TableSearch from '../../../../components/TableSearch';
//slices
import { openModal } from '../../../../store/slices/scansDoneSlice';
//constants
import { MODAL_TYPES } from '../../../../constants';
//assets
import UnitSettingIcon from '../../../units/components/UnitsHeader/components/UnitsOptionsMenu/icons/unitSettingIcon';


const Header = ({totalItems}) => {
    const { isMobile } = useSelector(state => state.config);
    
    const dispatch = useDispatch();
    
    const handleOpenModal = (modalName) => {
        dispatch(openModal({modalName}));
    };

    
    return (
        <>
        <div className="flex flex-col">
            <span className="text-gray-700 text-lg font-medium">Scans Log</span> 
            <span className="text-sm text-gray-500">{totalItems} Total Scans</span>     
        </div>
        <div className="flex justify-between items-center flex-wrap gap-y-4 mb-6 mt-6"> 
            <div className="flex">
                <div>
                    <TableSearch  />
                </div>
                <div className="ml-2">
                    <Button
                        onClick={()=>{handleOpenModal(MODAL_TYPES.SEARCH_FILTER_UPLOAD)}}
                        sx={{
                            backgroundColor: '#F2F2F3', 
                            padding: '10px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            color: '#333',              
                            minWidth: 0,                
                            '&:hover': {
                                backgroundColor: '#E0E0E1', 
                            },
                        }}
                    >
                        <UnitSettingIcon 
                            aria-hidden="true" 
                            className="h-5 w-5"  
                        />
                    </Button>
                </div>
            </div>
            
            <div className={"flex" + (isMobile ? " flex-row-reverse justify-end" : "")}>
            </div>
        </div>
        </>
    );
};

export default Header;