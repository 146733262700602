import React from 'react';
//mui
import { FormControl, Select, MenuItem, Typography } from '@mui/material';
//constants
import { PROPERTY_ROLE } from '../../../../../../constants';


const PropertyRoleSelect = ({ propertyRole, handleRoleChange }) => {
    return (
        <>
            <Typography sx={{ color: '#979AA1', fontSize: '12px', fontWeight: 500, mb: 1 }}>
                Choose a Role:
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <Select
                    labelId="property-role-select-label"
                    id="property-role-select"
                    value={propertyRole}
                    onChange={handleRoleChange}
                    sx={{
                        textAlign: 'left',
                        borderColor: '#D3D3D3',
                        '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D3D3D3',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#A9A9A9',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#4F46E5',
                        }
                    }}
                >
                    <MenuItem value={PROPERTY_ROLE.OWNER}>Owner</MenuItem>
                    <MenuItem value={PROPERTY_ROLE.MAINTENANCE}>Maintenance</MenuItem>
                    <MenuItem value={PROPERTY_ROLE.RESIDENT}>Resident</MenuItem>
                </Select>
            </FormControl>
        </>
    );
};

export default PropertyRoleSelect;