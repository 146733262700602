import React from 'react';
//mui
import { Switch } from '@mui/material';
import { styled } from '@mui/system';


const Android12Switch = styled((props) => <Switch {...props} />)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        backgroundColor: '#E0E0E0', // Default color when unchecked
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#FFFFFF',  // Thumb color when checked
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#4F46E5',  // Track background when checked
    },         
}));

export default Android12Switch;