import React from 'react';
//mui
import Typography from '@mui/material/Typography';
//components
import BaseModal from '../../../../../../../../components/Modal';
import EmptyState from "../../../../../../../../components/emptyState/empty_state";


const PreviousScansVideoModal = ({ open, handleClose, subject, videoLink }) => {
    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            minWidth={400}
            maxWidth={400}
        >
            <>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {subject}
                </Typography>
                {videoLink ? 
                    <video src={videoLink} controls playsInline autoPlay/>
                    :
                    <div className='h-72'>
                        <EmptyState text="No scan video available." size="md"/>
                    </div>
                }
            </> 
        </BaseModal>
    );
};

export default PreviousScansVideoModal;