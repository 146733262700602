import React, { useState } from 'react';
//mui
import { Box, IconButton, Tooltip, Typography, Button } from '@mui/material';
import ReplayIcon from "@mui/icons-material/Replay";
//assets
import SquarePen from '../../../../../icons/squarePen';


const ImageDisplay = ({ title, src, alt, isMobile, showTitle=true, toggleImgChangeMode=null, imgId=undefined }) => {
    const [imgError, setImgError] = useState(false);
    const [retryCount, setRetryCount] = useState(0);

    const handleRetry = () => {
        setImgError(false);
        setRetryCount((prev) => prev + 1);
    };

    return (
        <Box 
            sx={{ 
                position: 'relative', 
                // textAlign: 'center', 
                margin: '0 16px',
                border: src ? 'none' : '2px solid black',
                display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',
                // width: '100%',
                // height: '100%',
                // height: isMobile ? '28vh' : '58vh',
            }}
        >
            {/* Label inside the image or placeholder */}
            {showTitle &&
                <Box
                    sx={{
                        position: 'absolute',
                        top: '8px', 
                        left: '8px', 
                        backgroundColor: '#FFFFFF',
                        color: '#0C1222',
                        fontSize: '14px',
                        fontWeight: '500',
                        borderRadius: '12px',
                        padding: '4px 12px',
                        display: 'inline-block',
                    }}
                >
                    {title}
                </Box>
            }

            {(!isMobile && toggleImgChangeMode !== null) &&
                <Tooltip title='Update Frame'>
                    <IconButton onClick={toggleImgChangeMode} size="small" sx={{ position: 'absolute', top: '8px', right: '8px', backgroundColor: 'rgba(0,0,0,0.5)', padding: '8px' }}>
                        <SquarePen stroke="#ffffff" />
                    </IconButton>
                </Tooltip>
            }
            
            {/* <img
                id={imgId}
                src={src}
                alt={alt}
                style={{
                    // height: '100%',
                    // width: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    cursor: 'pointer',
                    // objectFit: 'contain',
                }}
                onClick={() => { window.open(src, '_blank'); }}
            /> */}
            {/* Display image or placeholder */}
            {src ? (
                !imgError ?
                    <img
                        src={`${src}${src.includes('?') ? "&" : "?"}retry=${retryCount}`}
                        id={imgId}
                        alt={alt}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            cursor: 'pointer',
                        }}
                        onClick={() => { window.open(src, '_blank'); }}
                        onError={() => {
                            if (retryCount < 2) {
                                handleRetry();
                            } else {
                                setImgError(true);
                            }
                        }}
                    /> 
                :
                    <div className="w-full flex flex-col items-center justify-center bg-gray-100 p-4" style={{ maxWidth: '100%', maxHeight: '100%' }}>
                        <p className="text-gray-500 text-lg">Failed Loading</p>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<ReplayIcon />}
                            onClick={handleRetry}
                            sx={{
                                marginTop: '8px'
                            }}
                        >
                            Retry
                        </Button>
                    </div>
            ) : (
                <Typography 
                    variant="body1" 
                    sx={{
                        color: 'black',
                        fontSize: '18px',
                    }}
                >
                    The image is not available
                </Typography>
            )}
        </Box>
    );
};

export default ImageDisplay;