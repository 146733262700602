import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
//mui
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';


const VideoPlayer = ({ videoSrc }) => {
    const isMobile = useSelector((state) => state.config?.isMobile);
    
    const [isPlaying, setIsPlaying] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isFullscreen, setIsFullScreen] = useState(false);
    const videoRef = useRef(null);
    
    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };
    
    const handlePause = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener('play', () => setIsPlaying(true));
            videoElement.addEventListener('pause', () => setIsPlaying(false));
        }

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            if (videoElement) {
                videoElement.removeEventListener('play', handlePlay);
                videoElement.removeEventListener('pause', handlePause);
            }
        };
    }, [])
    
    
    return (
        <div
            className={`relative ${isMobile ? 'w-full h-96' : 'w-64 h-56'} bg-gray-200 rounded-lg overflow-hidden`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {/* Video Element */}
            <video
                ref={videoRef}
                src={videoSrc}
                controls
                muted
                playsInline
                className={`w-full h-full ${isFullscreen ? 'object-contain' : 'object-cover'}`}
            >
                <p>The video is not available or is not supported by your browser</p>
            </video>
            
            {/* Play/Pause Button */}
            {(!isPlaying || (isPlaying && isHovered)) && (
                <button
                    onClick={isPlaying ? handlePause : handlePlay}
                    className="absolute inset-0 m-auto flex items-center justify-center bg-purple-600 rounded-full w-12 h-12 hover:bg-purple-700 transition"
                >
                    {isPlaying ? (
                            <PauseIcon className="h-6 w-6 text-white" />
                        ) : (
                            <PlayArrowIcon className="h-6 w-6 text-white" />
                        )
                    }
                </button>
            )}
        </div>
    );
};

export default VideoPlayer;