function dateTimeFormater(stringDate, format) {
    //acceptable formats that are delimitated by [':', '/', '.', '-', ' '].
    if (!format) return;
    const convertedDate = new Date(stringDate);
    const acceptableFormats = ['dd', 'mm', 'yyyy', 'HH', 'MM', 'SS', 'H', 'tt'];
    const dFormatter = (f, d) => !acceptableFormats.includes(f) ? f : addLeadingZero(
        f === 'dd' ? d.getDate() : f === 'mm' ? d.getMonth()+1 : f === 'yyyy' ? d.getFullYear() : f === 'HH' ? d.getHours() : f === 'H' ? getHours12(d.getHours()) : f === 'MM' ? d.getMinutes() : f === 'tt' ? getAmPm(d.getHours()) : d.getSeconds());
    return format.split(/[:/.\- ]/g).reduce((finalString, item) => acceptableFormats.includes(item) ? finalString.replace(item, dFormatter(item, convertedDate)) : finalString, format)
}

function getHours12(h) {
    return h % 12 || 12;
}

function getAmPm(h) {
    return h === 24 ? "AM" : h >= 12 ? "PM" : "AM";
}

function dateTimeFormaterUTC(stringDate, format) {
    //acceptable formats that are delimitated by [':', '/', '.', '-', ' '].
    if (!format) return;
    const convertedDate = new Date(stringDate);
    const acceptableFormats = ['dd', 'mm', 'yyyy', 'HH', 'MM', 'SS'];
    const dFormatter = (f, d) => !acceptableFormats.includes(f) ? f : addLeadingZero(
        f === 'dd' ? d.getUTCDate() : f === 'mm' ? d.getUTCMonth()+1 : f === 'yyyy' ? d.getUTCFullYear() : f === 'HH' ? d.getHours() : f === 'MM' ? d.getMinutes() : d.getSeconds());
    return format.split(/[:/.\- ]/g).reduce((finalString, item) => acceptableFormats.includes(item) ? finalString.replace(item, dFormatter(item, convertedDate)) : finalString, format)
}

function dateObjFormatter(dateObj, format) {
    if (!format) return;
    const acceptableFormats = ['dd', 'mm', 'yyyy', 'HH', 'MM', 'SS', 'H', 'tt'];
    const dFormatter = (f, d) => !acceptableFormats.includes(f) ? f : addLeadingZero(
        f === 'dd' ? d.getDate() : f === 'mm' ? d.getMonth()+1 : f === 'yyyy' ? d.getFullYear() : f === 'HH' ? d.getHours() : f === 'H' ? getHours12(d.getHours()) : f === 'MM' ? d.getMinutes() : f === 'tt' ? getAmPm(d.getHours()) : d.getSeconds());
    return format.split(/[:/.\- ]/g).reduce((finalString, item) => acceptableFormats.includes(item) ? finalString.replace(item, dFormatter(item, dateObj)) : finalString, format)
}

const addLeadingZero = (item) => parseInt(item) < 10 ? "0" + item : item;

function isToday(stringDate) {
    const today = new Date();
    const inputDate = new Date(stringDate);
    return today.getDate() === inputDate.getDate() && today.getMonth() === inputDate.getMonth() && today.getFullYear() === inputDate.getFullYear();
}

function daysAgo(stringDate) {
    const agoAttach = (value, t) => value + " " + t + (value > 1 ? "s ago" : " ago");
    const argDate = new Date(stringDate);
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const dNow = new Date();
    const daysBetween = Math.floor(Math.abs((argDate - dNow) / oneDay));
    const hoursAgo = daysBetween === 0 ? Math.floor(Math.abs((argDate - dNow) / (oneDay / 24))) : -1;
    const minutesAgo = hoursAgo <= 0 ? Math.floor(Math.abs((argDate - dNow) / (1000 * 60))) : -1;
    return daysBetween > 0 ? agoAttach(daysBetween, "day") : hoursAgo > 0 ? agoAttach(hoursAgo, "hour") : agoAttach(minutesAgo, "minute");
}

function addYears(date, years) {
    return new Date(date.setFullYear(date.getFullYear() + years));
}


export {
    dateTimeFormater,
    dateTimeFormaterUTC,
    dateObjFormatter,
    isToday,
    daysAgo,
    addYears
}