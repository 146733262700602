import React, { useState } from "react";
import { useSelector } from "react-redux"; 
//components
import BaseModal from "../../components/Modal"; 
import BulkUploadWizard from "./components/BulkUploadWizard";
import SnackBar from '../../components/SnackBar';
//constants
import { SeverityLevels } from '../../constants';


const BulkUploadWrapperModal = ({ open, handleClose, onComplete }) => {
    const isMobile = useSelector((state) => state.config.isMobile); 
    const [errorMessage, setErrorMessage] = useState(null);
    // const handleClose = () => setOpen(false);
    
    const handleErrorMessage = (message) => {
        setErrorMessage(message);
    };

    return (
        <>
            <BaseModal
                open={open}
                onClose={handleClose}
                minWidth={isMobile ? "95%" : 800} 
                maxWidth={isMobile ? "95%" : 1000} 
                maxHeight="90%"
            >
                <BulkUploadWizard handleClose={handleClose} handleErrorMessage={handleErrorMessage} onComplete={onComplete} />
            </BaseModal>
            
            {Boolean(errorMessage) && 
                <SnackBar
                    open={errorMessage !== null}
                    handleClose={() => setErrorMessage(null)}
                    severity={SeverityLevels.ERROR}
                    message={errorMessage}
                    duration={6000} 
                />
            }
        </>
    );
};

export default BulkUploadWrapperModal;