import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getClientData } from '../../utils/main_utils';
import { para_be } from '../../config';


// Slice for fetching end user data
export const fetchEndUserData = createAsyncThunk(
    'config/fetchEndUserData',
    async (_, { rejectWithValue }) => {
        try {
            const endUserData = await getClientData();
            return { data: {ip: endUserData[0], locality: endUserData[1]} };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

export const getClientLogo = createAsyncThunk(
    'config/getClientLogo',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${para_be}/media/get_company_logo`, { withCredentials: true });

            if (response.status === 200 && response.data?.result?.length > 0) {
                return response.data.result;
            } else {
                console.log("Client logo not found or invalid response.");
                return rejectWithValue(response.data?.msg || 'Client logo invalid or not found');
            }
        } catch (error) {
            console.err("Error fetching client logo:", error);
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

const configSlice = createSlice({
    name: 'config',
    initialState: {
        isMobile: null,
        sidebarOpenMobile: false,
        sidebarOpen: true,
        lastPage: null,
        endUserData: null,
        loading: false,
        error: null,
        clientLogo: null,
        loadingClientLogo: false,
        errorClientLogo: null,
    },
    reducers: {
        setIsMobile(state, action) {
            state.isMobile = action.payload;
        },
        setSidebarOpenMobile(state, action) {
            state.sidebarOpenMobile = action.payload;
        },
        setSidebarOpen(state, action) {
            state.sidebarOpen = action.payload;
        },
        setLastPage(state, action) {
            state.lastPage = action.payload;
        },
        clearIsMobile(state) {
            state.isMobile = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEndUserData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchEndUserData.fulfilled, (state, action) => {
                state.loading = false;
                state.endUserData = action.payload.data;
            })
            .addCase(fetchEndUserData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong while fetching client PMS data';
            })
            .addCase(getClientLogo.pending, (state) => {
                state.loadingClientLogo = true;
                state.errorClientLogo = null;
            })
            .addCase(getClientLogo.fulfilled, (state, action) => {
                state.loadingClientLogo = false;
                state.clientLogo = action.payload;
            })
            .addCase(getClientLogo.rejected, (state, action) => {
                state.loadingClientLogo = false;
                state.errorClientLogo = action.payload || 'Something went wrong while fetching client PMS data';
            });
    }
});

export const { setIsMobile, clearIsMobile, setSidebarOpenMobile, setSidebarOpen, setLastPage } = configSlice.actions;

export default configSlice.reducer;
