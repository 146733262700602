import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//mui
import { Box, Typography, Avatar } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
//slices
import { getClientLogo } from '../../../../../../store/slices/configSlice';


const PropertyManagementCard = () => {
    const dispatch = useDispatch();
    const { isMobile, clientLogo } = useSelector((state) => state.config);
    const { userData: settingUser, clientData: settingClient } = useSelector((state) => state.auth);
    const { management, previewMode } = useSelector((state) => state.reports);
    
    useEffect(() => {
        if (!clientLogo) {
            dispatch(getClientLogo());
        }
    }, []);

    
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                padding: '12px',
                border: '1px solid #F2F2F3',
                borderRadius: '8px',
                height: 'fit-content',
                minWidth: '240px'
            }}
        >
            {/* Top Row with Logo and Name */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ width: 40, height: 40, borderRadius: '50%', overflow: 'hidden' }}>
                    <img src={previewMode ? management?.clientLogo : clientLogo} alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                    {/* {clientLogo ?
                        <img src={clientLogo} alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                        :
                        <Avatar 
                            src="https://via.placeholder.com/40" 
                            alt="Company Logo" 
                            sx={{ width: 40, height: 40 }}
                        />
                    } */}
                </Box>
                <Box>
                    <Typography 
                        sx={{ fontSize: '14px', fontWeight: 600, color: '#0C1222' }}
                    >
                        {previewMode ? management?.clientData?.name : settingClient?.name}
                    </Typography>
                    <Typography 
                        sx={{ fontSize: '12px', fontWeight: 600, color: '#777A83' }}
                    >
                        {previewMode ? management?.userData?.fullName : settingUser?.fullName}
                    </Typography>
                </Box>
            </Box>
            
            {/* Bottom Row with Contact Info on the Same Line */}
            {/* <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}> */}
                {/* Phone Section */}
                {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, flexShrink: 0 }}>
                    <PhoneIcon sx={{ fontSize: 16, color: '#777A83' }} />
                    <Typography sx={{ fontSize: '12px', fontWeight: 600, color: '#777A83', whiteSpace: 'nowrap' }}>
                        (480) 555-0103
                    </Typography>
                </Box> */}
                {/* Email Section */}
                {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, flexShrink: 0, ml:2 }}>
                    <EmailIcon sx={{ fontSize: 16, color: '#777A83' }} />
                    <Typography sx={{ fontSize: '12px', fontWeight: 600, color: '#777A83', whiteSpace: 'nowrap' }}>
                        info@primeraproperty.com
                    </Typography>
                </Box> */}
            {/* </Box> */}
        </Box>
    );
};

export default PropertyManagementCard;