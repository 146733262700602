import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import axios from 'axios';
//mui
import {
    Button, Alert, Tooltip, IconButton, CircularProgress
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
//assets
import WarningIcon from '../../../../icons/warningIcon';
//constants
import { para_be } from '../../../../../../config';
import { SeverityLevels } from '../../../../../../constants';


const turnReportToBaseline = async (rid) => {
    const response = await axios.post(
        `${para_be}/scan/set_scan_as_baseline`,
        { rid },
        { withCredentials: true }
    );
    
    if (response.status !== 200) {
      throw new Error('Failed to turn report to baseline');
    }
    
    return response.data;
};

const AlertBaseline = ({ handleNotifState, rid }) => {
    const { isMobile } = useSelector(state => state.config);
    const mutation = useMutation(
        () => turnReportToBaseline(rid),
        {
            onMutate: () => {},
            onSuccess: () => {
                handleNotifState({
                    text: 'Successfully turned the scan to baseline',
                    type: SeverityLevels.SUCCESS
                });
            },
            onError: () => {
                handleNotifState({
                    text: 'Something went wrong while turning this scan to baseline',
                    type: SeverityLevels.ERROR
                });
            },
        }
    );


    return (
        <Alert
            icon={<WarningIcon styleClass="mt-0.5" />}
            severity="warning"
            sx={{
                backgroundColor: '#FF57220D',
                color: '#FF5722',
                '& .MuiAlert-message': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: isMobile ? '' : 'center',
                    width: '100%',
                    flexDirection: isMobile ? 'column' : 'row',
                },
            }}
        >
            <div>
                NO BASELINE: This report was created from a single scan, and detections are made based on that scan alone.
            </div>
            
            <div className={`flex items-center space-x-2 ${isMobile ? 'mt-4' : ''}`}>
                <Button
                    variant="text"
                    startIcon={mutation.isLoading ? <CircularProgress size={20} color="inherit" /> : <RefreshIcon />}
                    onClick={() => mutation.mutate()}
                    disabled={mutation.isLoading}
                    sx={{
                        color: '#4F46E5',
                        textTransform: 'none',
                        padding: 0,
                        '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline' },
                    }}
                >
                    Turn to Baseline
                </Button>
                
                <Tooltip title="All future scans of this unit will be compared to this video">
                    <IconButton sx={{ color: '#A8ADB8', padding: 0 }}>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </Alert>
    );
};

export default AlertBaseline;