import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import axios from 'axios';
//slices
import { 
    fetchAllExportReports, 
    setCurrentExportReport,
    setCustomText,
    setSelectedCurrency,
    setCheckboxStates,
    setItemDescription,
    setItemPrice,
    setFetchExportReportLoding,
    togglePropertyRole,
    setCurrentExportExistence,
    setItemTitle,
    setManagementData
} from '../../../../../store/slices/reportsSlice';
//constants
import { para_be } from '../../../../../config';


// API functions
const fetchAllExportReportsAPI = async (reportId) => {
    const response = await axios.post(
        `${para_be}/export_reports/list`,
        { report_id: reportId },
        { withCredentials: true }
    );
    
    if (response.status !== 200 || response.data.status !== 200) {
        throw new Error(response.data.msg || 'Failed to fetch export reports');
    }
    
    return response.data.result;
};


const deactivateExportReportAPI = async (exportId) => {
    const response = await axios.post(
        `${para_be}/export_reports/change_status`,
        { export_id: exportId, status: false },
        { withCredentials: true }
    );
    
    if (response.status !== 200 || response.data.status !== 200) {
        throw new Error(response.data.msg || 'Failed to deactivate export report');
    }
    
    return response.data;
};


// Custom hook
export const useExportReports = (rid,setOpenModal) => {   
    const dispatch = useDispatch();
    
    const fetchExportReportAPI = async (exportId) => {
        dispatch(setFetchExportReportLoding(true));
        
        const response = await axios.post(
            `${para_be}/export_reports/get_report`,
            { export_id: exportId },
            { withCredentials: true }
        );
        if (response.status !== 200 || response.data.status !== 200) {
            dispatch(setFetchExportReportLoding(false));
            throw new Error(response.data.msg || 'Failed to fetch export report');
        }
    
        console.log('response:', response.data.result);
    
        const result = response.data.result;
    
        const { exported_data, export_type } = result;
        const { message, currency, items, management } = exported_data;
    
        // Dispatch for message and currency
        dispatch(setManagementData(management));
        dispatch(togglePropertyRole(export_type));
        dispatch(setCustomText(message));
        dispatch(setSelectedCurrency(currency));
    
        // Iterate over the items object and dispatch actions for each item
        Object.keys(items).forEach((dataKey) => 
            Object.entries(items[dataKey]).forEach(([object_id, { title=null, description, price }]) => {
                dispatch(setCheckboxStates({ object_id, checked: true, category: dataKey }));
                if (title) dispatch(setItemTitle({ object_id, title, category: dataKey }));
                dispatch(setItemDescription({ object_id, description, category: dataKey }));
                dispatch(setItemPrice({ object_id, price, category: dataKey }));
            })
        );
        dispatch(setFetchExportReportLoding(false));
    
        return response.data.result;
    };

    const fetchReports = useMutation(fetchAllExportReportsAPI, {
        onSuccess: (data) => {
            dispatch(fetchAllExportReports(data));
        },
        onError: (error) => {
            console.error('Error fetching export reports:', error.message || error);
        },
    });
    
    const deactivateReport = useMutation(deactivateExportReportAPI, {
        onSuccess: () => {
            fetchReports.mutate(rid); 
            setOpenModal(false);
        },
        onError: (error) => {
            console.error('Error deactivating export report:', error.message || error);
        },
    });
    
    const fetchReport = useMutation(fetchExportReportAPI, {
        onSuccess: (data) => {
            console.log('Fetched Export Report:', data);
            dispatch(setCurrentExportReport(data));
        },
        onError: (error) => {
            console.error('Error fetching export report:', error.message || error);
            dispatch(setCurrentExportExistence(false));
        },
    });
    
    
    return {
        fetchReports: {
            mutate: fetchReports.mutate,
            isLoading: fetchReports.isLoading,
        },
        deactivateReport: {
            mutate: deactivateReport.mutate,
            isLoading: deactivateReport.isLoading,
        },
        fetchReport: {
            mutate: fetchReport.mutate,
            isLoading: fetchReport.isLoading,
        },
    };
};