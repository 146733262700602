import React from 'react'

const PhoneIcon= ({ fill = 'none', innerFill = "#C2C4C8", width = '12px', height = '12px', styleClass = "" }) => {
    return (
        <svg
            className={`transition duration-75 ${styleClass}`}
            width={width}
            height={height}
            fill={fill}
            viewBox='0 0 12 12'
        >
            <g clipPath="url(#clip0_5367_13630)">
                <path
                    d="M10.9987 8.46005V9.96005C10.9993 10.0993 10.9708 10.2371 10.915 10.3647C10.8592 10.4923 10.7774 10.6068 10.6748 10.701C10.5722 10.7951 10.451 10.8668 10.3191 10.9114C10.1872 10.956 10.0474 10.9726 9.90875 10.96C8.3702 10.7927 6.89233 10.267 5.59375 9.42505C4.38566 8.65738 3.36141 7.63313 2.59375 6.42505C1.7489 5.12057 1.22305 3.6355 1.05875 2.09005C1.04625 1.95178 1.06268 1.81243 1.107 1.68086C1.15131 1.54929 1.22253 1.42839 1.31613 1.32586C1.40973 1.22332 1.52365 1.1414 1.65064 1.08531C1.77763 1.02922 1.91492 1.00018 2.05375 1.00005H3.55375C3.7964 0.99766 4.03164 1.08359 4.21563 1.24181C4.39961 1.40004 4.51978 1.61977 4.55375 1.86005C4.61689 2.34011 4.73431 2.81146 4.90375 3.26505C4.97102 3.44401 4.98558 3.63851 4.9457 3.82549C4.90582 4.01247 4.81318 4.1841 4.67875 4.32005L4.04375 4.95505C4.75552 6.20682 5.79197 7.24327 7.04375 7.95505L7.67875 7.32005C7.81469 7.18562 7.98632 7.09297 8.17331 7.0531C8.36029 7.01322 8.55478 7.02778 8.73375 7.09505C9.18733 7.26449 9.65868 7.38191 10.1387 7.44505C10.3816 7.47931 10.6034 7.60165 10.762 7.7888C10.9206 7.97594 11.0048 8.21484 10.9987 8.46005Z"
                    stroke="#50535C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5367_13630">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default PhoneIcon;