import React from 'react';
//mui
import { Box, Typography } from '@mui/material';


const TenantMessage = ({ customText=null, totalCharge=null }) => {
    return (
        <>
            {(customText && customText !== '') &&
                <Box className="flex flex-col p-4">
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: '16px',
                            color: '#0C1222',
                        }}
                    >
                        Note
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: '#0C1222',
                        }}
                    >
                        {customText}
                    </Typography>
                </Box>
            }

            {(totalCharge && totalCharge !== '') &&
                <Box className="flex flex-col p-4">
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: '16px',
                            color: '#0C1222',
                        }}
                    >
                        Charge Details
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: '#0C1222',
                            marginTop: '4px',
                        }}
                    >
                        Total charge of: {totalCharge}
                    </Typography>
                </Box>
            }
        </>
    );
};

export default TenantMessage;