import React from 'react'

const DollarSign= ({ fill = 'none', innerFill = "#C2C4C8", width = '12px', height = '12px', styleClass = "" }) => {
    return (
        <svg
            className={`transition duration-75 ${styleClass}`}
            width={width}
            height={height}
            fill={fill}
            viewBox='0 0 12 12'
        >
            <g clipPath="url(#clip0_5367_13651)">
                <path
                    d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                    stroke="#50535C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 4H5C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5C4 5.26522 4.10536 5.51957 4.29289 5.70711C4.48043 5.89464 4.73478 6 5 6H7C7.26522 6 7.51957 6.10536 7.70711 6.29289C7.89464 6.48043 8 6.73478 8 7C8 7.26522 7.89464 7.51957 7.70711 7.70711C7.51957 7.89464 7.26522 8 7 8H4M6 9V3"
                    stroke="#50535C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5367_13651">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DollarSign