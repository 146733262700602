import { useSelector } from 'react-redux';
//components
import ScanCompletionPieChart from './components/scan-completion-pie-chart';
import EmptyState from '../../components/EmptyState';


const ScanCompletionSection = () => {
    const { allCount, categoryFilter } = useSelector(state => state.dashboard);
    const sum = (allCount && allCount?.[categoryFilter]) ? allCount[categoryFilter] : 0;
    // const sum = allCount ? Object.values(allCount).reduce((acc, curr) => acc + curr, 0) : 0;

    return (
        <div className="hide-mobile sm:w-9/12 lg:w-6/12 xl:w-5/12 sm:mt-0 mt-6 relative rounded-lg bg-white shadow ml-0 sm:ml-8 flex flex-col">
            <div className="truncate px-4 py-2 h-12">
                <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Stats</h1>
            </div>
            <div className="flex justify-center items-center h-full">    
                {sum !== 0 ? 
                    <div className="flex-1 truncate sm:px-6 py-2">
                        <ScanCompletionPieChart />
                    </div> 
                    : 
                    <EmptyState 
                        headerText="No Data Found" 
                        decriptionText="Get started by adding your property and tenant data to Paraspot." />
                }
            </div>
        </div>
    );
};

export default ScanCompletionSection;