import React from 'react';
//mui
import { Modal, Box, Typography, Button, CircularProgress } from '@mui/material';


const ConfirmationModal = ({ open, onClose, onConfirm, title, description, isLoading }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="confirmation-modal-title"
            aria-describedby="confirmation-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: '8px',
                }}
            >
                <Typography
                    id="confirmation-modal-title"
                    variant="h6"
                    sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
                >
                    {title}
                </Typography>
                <Typography
                    id="confirmation-modal-description"
                    variant="body2"
                    sx={{ mb: 3, textAlign: 'center', color: 'text.secondary' }}
                >
                    {description}
                </Typography>
                
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button 
                        variant="outlined" 
                        onClick={onClose} 
                        sx={{
                            fontWeight: 700,
                            fontSize: '12px',
                            width: '67px',
                            height: '36px',
                            marginTop: '6px',
                            textTransform: 'none',
                            marginRight: '10px'                 
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onConfirm}    
                        disabled={isLoading} 
                        sx={{
                            backgroundColor: '#4F46E5',
                            color: 'white',
                            fontWeight: 700,
                            fontSize: '12px',
                            width: '67px',
                            height: '36px',
                            marginTop: '6px',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#3C3EB8',
                            }
                        }}
                    >
                        {isLoading ? <CircularProgress size={20} /> : 'Confirm'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;