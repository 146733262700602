//mui
import { Box } from '@mui/material';


const Thumbnails = ({ images, currentImageIndex, handleImageChange, isMobile }) => {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: '10px',
                mr: isMobile ? 0 : 4,
                mb: isMobile ? 1 : 0,
                mt: isMobile ? 1 : 'inherent',
            }}
        >
            {/* Render media thumbnails */}
            {images.map((image, i) => (
                <img
                    key={i}
                    src={image.media}
                    alt="Thumbnail"
                    className={`w-24 h-24 cursor-pointer object-cover ${currentImageIndex === i ? 'border-2 border-indigo-500' : ''} rounded-lg`}
                    onClick={() => handleImageChange(i)}
                />
            ))}
        </Box>
    );
};

export default Thumbnails;