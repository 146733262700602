import React from 'react';
import Button from '@mui/material/Button';
import CircularLoader from '../../loaders/CircularLoader';

const CustomButton = ({ variant, color, onClick, disabled, loading, autoFocus, children, sx, startIcon }) => {
    return (
        <Button
            autoFocus={autoFocus}
            variant={variant}
            startIcon={startIcon}
            color={color}
            onClick={onClick}
            disabled={loading || disabled}
            sx={sx}
        >
            {loading ? <CircularLoader size={24} /> : children}
        </Button>
    );
};

export default CustomButton;
