import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//mui
import { Box, Button, Popover } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
//components
import ConfirmationModal from './components/ConfirmationModal';
import PreviousVersions from './components/PreviousVersions';
import PropertyRoleSelect from './components/PropertyRoleSelect';
import ExportNameField from './components/ExportNameField';
//slices
import {
  toggleEditMode,
  EDIT_STATE,
  togglePropertyRole,
  toggleExportName,
  setCurrentExportId,
} from '../../../../store/slices/reportsSlice';
//hooks
import { useExportReports } from './hooks/useExportReports';
//constants
import { PROPERTY_ROLE } from '../../../../constants';


const ViewReportHistory = ({ rid, handleNotifState }) => {
    const dispatch = useDispatch();
    const { exportReport } = useSelector((state) => state.reports);
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedExportId, setSelectedExportId] = useState(null);
    const [hasFetched, setHasFetched] = useState(false);
    
    const { fetchReports, deactivateReport, fetchReport } = useExportReports(rid, setOpenModal);
    
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        dispatch(togglePropertyRole(PROPERTY_ROLE.RESIDENT));
        dispatch(toggleExportName(''));
    };
    
    const handleClose = () => {
        setAnchorEl(null);
        setHasFetched(false);
    };
    
    const handleRoleChange = (event) => {
        dispatch(togglePropertyRole(event.target.value));
    };
    
    const handleExportNamChange = (event) => {
        dispatch(toggleExportName(event.target.value));
    };
    
    const handleOpenModal = (exportId) => {
        setSelectedExportId(exportId);
        setOpenModal(true);
    };
    
    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedExportId(null);
    };
    
    const handleConfirmDeactivate = () => {
        if (selectedExportId) {
        deactivateReport.mutate(selectedExportId);
        }
    };
    
    const handleViewReport = (exportId) => {
        dispatch(toggleEditMode(EDIT_STATE.START));
        fetchReport.mutate(exportId);
    };
    
    const isPopoverOpen = Boolean(anchorEl);
    
    useEffect(() => {
        if (isPopoverOpen && !hasFetched) {
        fetchReports.mutate(rid);
        setHasFetched(true);
        }
    }, [isPopoverOpen, fetchReports, rid, hasFetched]);
    
    const isCreateButtonDisabled = !exportReport.exportName || exportReport.exportName.trim() === '';
    
    
    return (
        <>
            {/* Export Button */}
            <Button
                onClick={handleOpen}
                sx={{
                    backgroundColor: '#4F46E5',
                    color: 'white',
                    fontWeight: 700,
                    fontSize: '12px',
                    width: '67px',
                    height: '36px',
                    marginTop: '6px',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#3C3EB8',
                    }
                }}
            >
                Export
            </Button>
        
            {/* Popover for "View Report History" */}
            <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                disableScrollLock
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ padding: 2, width: { xs: '100%', sm: '450px' } }}>
                    {/* Create New Button */}
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        sx={{
                            fontSize: '14px',
                            border: '1px solid #F2F2F3',
                            color: '#0C1222',
                            textTransform: 'none',
                            mb: 2,
                            fontWeight: 600,
                            width: '100%'
                        }}
                        onClick={() => {
                            dispatch(setCurrentExportId(undefined));
                            dispatch(toggleEditMode(EDIT_STATE.START));
                        }}
                        disabled={isCreateButtonDisabled}
                    >
                        Create New
                    </Button>
                    <ExportNameField exportName={exportReport.exportName} handleExportNamChange={handleExportNamChange} />
                    <PropertyRoleSelect propertyRole={exportReport.propertyRole} handleRoleChange={handleRoleChange} />
                    <PreviousVersions
                        fetchReports={fetchReports}
                        exportReport={exportReport}
                        handleViewReport={handleViewReport}
                        handleOpenModal={handleOpenModal}
                        handleNotifState={handleNotifState}
                    />
                    <ConfirmationModal
                        open={openModal}
                        onClose={handleCloseModal}
                        onConfirm={handleConfirmDeactivate}
                        title="Delete Export"
                        description="Are you sure you want to delete this export?"
                        isLoading={deactivateReport.isLoading}
                    />
                </Box>
            </Popover>
        </>
    );
};

export default ViewReportHistory;