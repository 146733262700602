import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//mui
import { Button } from '@mui/material';
//components
import ReactQuill from 'react-quill';
import BaseModal from '../../../../../Modal';
import CustomButton from '../../../../../buttons/CustomButton';
//utils
import axios from '../../../../../../axios/axios';
//constants
import { para_be } from '../../../../../../config';
//slices


const INITIAL_STATE = {
    subject: "Inspection Request",
    body: `Hi there,<br/>Please find the link to the inspection scan for for property %PROPERTY_NAME% below.<br/><br/>%SCAN_LINK%<br/><br/>Let me know if you have any questions or need help!<br/><br/>Best,<br/><br/>`
}


const ShareLinkViaEmail = ({ open, handleClose, setNotifState, subject, shareLink, inspectionType }) => {
    const endUserData = useSelector(state => state.config.endUserData);
    // loading states
    const [loadingBtn, setLoadingBtn] = useState(false);
    // states
    const [toEmail, setToEmail] = useState(null);
    const [emailSubject, setEmailSubject] = useState(INITIAL_STATE.subject);
    const [emailBody, setEmailBody] = useState(INITIAL_STATE.body.replace("%PROPERTY_NAME%", subject));
    
    
    const onCloseModal = (force=false) => {
        if (loadingBtn && !force) return;
        handleClose();
    };

    const sendEmail = async () => {
        setLoadingBtn(true);
        if (!toEmail || !emailSubject || !emailBody) {
            setNotifState({ type: 'error', text: 'Please fill all fields' });
            setLoadingBtn(false);
            return;
        }

        const body = { 
            targetEmail: toEmail, 
            emailBody: emailBody.replace(
                "%SCAN_LINK%", 
                `<a class="primary-btn" href="${window.location.origin}/inspection/${inspectionType[0]}/${shareLink}">${inspectionType[1]} Scan</a>`
            ), 
            subject: emailSubject
        };

        try {
            const response = await axios.post(`${para_be}/share/email`, body, { withCredentials: true });
            if (response.status === 200 && response.data.status === 200) {
                setNotifState({ type: 'success', text: 'Email sent successfully' });
                onCloseModal(true);
            } else {
                setNotifState({ type: 'error', text: response.data.msg });
            }
        } catch (error) {
            setNotifState({ type: 'error', text: 'Something went wrong while sending email. Please try again later or reach out to support.' });
            console.error('Error sending email:', error);
        } finally {
            setLoadingBtn(false);
        }
    }

    
    
    return (
        <BaseModal open={open} onClose={onCloseModal} minWidth={600} maxWidth={600}>
            <div className="flex flex-col gap-y-6">
                <div>
                    <div className="text-gray-700 text-lg font-medium">Share Link via Email</div> 
                    <div className="text-gray-400 text-sm mt-1">
                        Draft a message to share the scan link with the tenant or one of your team members via email.
                        To pass along the scan link please use %SCAN_LINK% placeholder in the email body.
                    </div>
                </div>

                <div className='flex flex-col flex-grow gap-y-4 mt-2'>
                    <div className="flex flex-col gap-2">
                        <label className="text-md font-medium text-gray-700">
                            To
                        </label>
                        <input 
                            className="border border-gray-300 rounded-md py-3 px-4" 
                            type="text" 
                            placeholder="Enter Email Address"
                            onChange={(e) => setToEmail(e.target.value)}
                        />
                    </div>

                    <div className="flex flex-col gap-2">
                        <label className="text-md font-medium text-gray-700">
                            Subject
                        </label>
                        <input 
                            className="border border-gray-300 rounded-md py-3 px-4" 
                            type="text" 
                            placeholder="Enter Email Subject"
                            value={emailSubject}
                            onChange={(e) => setEmailSubject(e.target.value)}
                        />
                    </div>

                    <div className="simple-input-label wrapper-text">
                        <label className="simple-input-label text-1-3">
                            Email Body
                        </label>

                        <ReactQuill 
                            className="email-body min-h-60"
                            theme={"snow"}
                            value={emailBody}
                            onChange={(content) => setEmailBody(content)}
                            modules={{
                                toolbar: [
                                    [{ 'header': [1, 2, false]}],
                                    [],
                                    ['bold', 'italic', 'underline'],
                                    [],
                                    [],
                                    []
                                ],
                                clipboard: {
                                    // toggle to add extra line breaks when pasting HTML:
                                    matchVisual: false,
                                }
                            }}
                            formats={['header', 'bold', 'italic', 'underline']}
                            bounds={'.app'}
                        />
                    </div>
                </div>

                <div className={"border-t border-slate-500 py-4 bg-white"}>
                    <div className='flex justify-between'>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#fff', 
                                strokeWidth: '1px',
                                stroke: '#F2F2F3',
                                fontSize: '12px',
                                textTransform: 'none',
                                borderRadius: '8px',
                                padding: '9px 15px',
                                color: '#0C1222',
                                minWidth: 0,
                                '&:hover': {
                                    backgroundColor: '#F7F7F7', 
                                },
                            }}
                            disabled={loadingBtn}
                            onClick={() => { onCloseModal(); }}
                        >
                            Cancel
                        </Button>
                        <CustomButton 
                            variant="contained"
                            sx={{
                                backgroundColor: '#4F46E5', 
                                color: 'white',
                                fontSize: '12px',
                                textTransform: 'none',
                                borderRadius: '8px',
                                padding: '9px 15px',
                                '&:hover': { backgroundColor: '#4338CA' } 
                            }}
                            loading={loadingBtn}
                            onClick={sendEmail}
                        >
                            Send Email
                        </CustomButton>
                    </div>
                </div>
            </div>
        </BaseModal>
    );
};

export default ShareLinkViaEmail;