import React from 'react';

const SquarePen = ({ fill = 'none', innerFill = "#C2C4C8", stroke="#A8ADB8", width = '18', height = '18', styleClass = "" }) => {
    const numericWidth = parseInt(width, 10);
    const numericHeight = parseInt(height, 10);
    
    return (
        <svg
            className={`transition duration-75 ${styleClass}`}
            width={numericWidth + "px"}
            height={numericHeight + "px"}
            fill={fill}
            viewBox={`0 0 ${numericWidth} ${numericHeight}`}
        >
            <path
                d="M9 2.75H3.75C3.35218 2.75 2.97064 2.90804 2.68934 3.18934C2.40804 3.47064 2.25 3.85218 2.25 4.25V14.75C2.25 15.1478 2.40804 15.5294 2.68934 15.8107C2.97064 16.092 3.35218 16.25 3.75 16.25H14.25C14.6478 16.25 15.0294 16.092 15.3107 15.8107C15.592 15.5294 15.75 15.1478 15.75 14.75V9.5"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.7803 2.4699C14.0787 2.17153 14.4834 2.00391 14.9053 2.00391C15.3273 2.00391 15.732 2.17153 16.0303 2.4699C16.3287 2.76826 16.4963 3.17294 16.4963 3.5949C16.4963 4.01685 16.3287 4.42153 16.0303 4.7199L9.27059 11.4804C9.09251 11.6583 8.8725 11.7886 8.63084 11.8591L6.47609 12.4891C6.41156 12.508 6.34315 12.5091 6.27803 12.4924C6.2129 12.4757 6.15346 12.4418 6.10593 12.3943C6.05839 12.3468 6.02451 12.2873 6.00783 12.2222C5.99114 12.1571 5.99227 12.0887 6.01109 12.0241L6.64109 9.8694C6.71199 9.62793 6.84249 9.40819 7.02059 9.2304L13.7803 2.4699Z"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SquarePen;