import { useState } from 'react';
//mui
import { Box } from '@mui/material';


const SelectableThumbnails = ({ images, isMobile, onSelect }) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: '10px',
                mr: isMobile ? 0 : 4,
                mb: isMobile ? 1 : 0,
                mt: isMobile ? 1 : 'inherent',
                paddingRight: '2px',
                overflowY: 'auto',
            }}
        >
            {/* Render media thumbnails */}
            {images.map((image, i) => (
                <img
                    key={i}
                    src={image}
                    alt="Thumbnail"
                    className={`w-24 h-24 cursor-pointer object-cover ${selectedImageIndex === i ? 'border-indigo-500' : 'hover:border-indigo-300'} border-2 rounded-lg`}
                    onClick={() => {
                        setSelectedImageIndex(i);
                        if (onSelect) onSelect(image);
                    }}
                />
            ))}
        </Box>
    );
};

export default SelectableThumbnails;