import React from 'react'

const MailIcon= ({ fill = 'none', innerFill = "#C2C4C8", width = '12px', height = '12px', styleClass = "" }) => {
    return (
        <svg
            className={`transition duration-75 ${styleClass}`}
            width={width}
            height={height}
            fill={fill}
            viewBox='0 0 12 12'
        >
            <path
                d="M10 2H2C1.44772 2 1 2.44772 1 3V9C1 9.55228 1.44772 10 2 10H10C10.5523 10 11 9.55228 11 9V3C11 2.44772 10.5523 2 10 2Z"
                stroke="#50535C"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11 3.5L6.515 6.35C6.36064 6.44671 6.18216 6.49801 6 6.49801C5.81784 6.49801 5.63936 6.44671 5.485 6.35L1 3.5"
                stroke="#50535C"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default MailIcon