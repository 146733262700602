import React from 'react';
import { Box, IconButton, Typography, } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setEditExportMode, reseItemMetadata, resetCurrentExportReport } from '../../../../store/slices/reportsSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackToViewMode = () => {
    const dispatch = useDispatch();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
                paddingTop: '1.5rem',
                cursor: 'pointer',
            }}
            onClick={() => {
                dispatch(setEditExportMode(false));
                dispatch(reseItemMetadata());
                dispatch(resetCurrentExportReport());
            }}
        >
            {/* Left arrow button */}
            <IconButton
                sx={{
                    backgroundColor: '#F2F2F3',
                    color: '#A8ADB8',
                    borderRadius: '50%',
                    width: '32px',
                    height: '32px',
                    marginRight: '8px',
                    '&:hover': {
                        backgroundColor: '#E0E0E1',
                    },
                }}
            >
                <ArrowBackIcon fontSize='small' />
            </IconButton>

            {/* Text label */}
            <Typography
                sx={{
                    color: '#0C1222',
                    fontWeight: 700,
                    fontSize: '12px',
                }}
            >
                Back to View Mode
            </Typography>
        </Box>
    );
};

export default BackToViewMode;