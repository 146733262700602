import React from 'react';
//mui
import { Tooltip, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
//components
import Checkbox from '../../../../../../components/Checkbox';
//utils
import { capitalizeString, postReqOptBuilder } from '../../../../../../utils/main_utils';
//constants
import { para_be } from '../../../../../../config';

const ItemLabel = ({ report_id, item, index, previewMode, editExportMode, finishExportMode, tabView, checkboxStates, handleCheckboxChange, dispatch, setNotifState }) => {
    
    const onIgnoreItem = () => {
        fetch(para_be + "/reports/ignore_report_item", postReqOptBuilder({report_id, item: item.object_id}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setNotifState({ text: "Item ignored successfully", type: "success" });
            } else {
                setNotifState({ text: "Failed to ignore report item. Please try again later", type: "error" });
            }
        })
    }
    
    return (
        <div className='flex items-center justify-between mb-2'>
            <div
                className='text-xs font-medium'
                style={{
                    color: '#4F46E5',
                    border: '1px solid #4F46E51A',
                    borderRadius: '4px',
                    padding: '2px 8px',
                    backgroundColor: '#F2F2F3',
                }}
            >
                {item?.category ? capitalizeString(item.category.replace(/_/g, " "), true) ?? "no label" : item?.issue_type ?? "no label"}
            </div>
            {!previewMode &&
                <div className='flex'>
                    {editExportMode ?
                        <Checkbox
                            checked={checkboxStates?.[tabView]?.[item.object_id] || false}
                            onChange={(e) => handleCheckboxChange(item.object_id, e)}
                            sx={{ padding: '0' }}
                        /> :
                        <Tooltip title='Ignore Item'>
                            <IconButton size="small" onClick={onIgnoreItem}>
                                <VisibilityIcon sx={{ color: '#ABADB8' }} />
                            </IconButton>
                        </Tooltip>
                    }
                </div>
            }
        </div>
    );
};

export default ItemLabel;