import React, { useEffect } from 'react';
import { Modal, Box, Typography, Button, CircularProgress, Link as MuiLink } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { purchaseReport, checkIsReportPurchased } from '../../../../../store/slices/reportsSlice';

const LimitModal = ({ open, rid }) => {
    const dispatch = useDispatch();
    const { 
        purchasedLoader,
        purchaseStatus,
        receiptUrl,
    } = useSelector((state) => state.reports);
    
    const handlePurchase = async () => {
        await dispatch(purchaseReport({ rid }));
    };
    
    const handleCheckPurchaseStatus = () => {
        dispatch(checkIsReportPurchased({ rid }));
    };

    return (
        <Modal
            open={open}
            aria-labelledby="limit-modal-title"
            aria-describedby="limit-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    textAlign: 'center',
                }}
            >
                <Typography id="limit-modal-title" variant="h6" sx={{ mb: 2 }}>
                    Access Restricted
                </Typography>
                <Typography id="limit-modal-description" sx={{ mb: 4, color: 'text.secondary' }}>
                    You have reached the usage limit for your current plan. Upgrade your plan to unlock additional features or purchase individual reports for access.
                </Typography>
                
                {/* Display purchase status */}
                {purchaseStatus && (
                    <Typography sx={{ mb: 2, color: receiptUrl ? 'green' : 'red' }}>
                        {purchaseStatus}
                    </Typography>
                )}
                
                {/* Display receipt URL if available */}
                {receiptUrl && (
                    <MuiLink
                        href={receiptUrl}
                        target="_blank"
                        rel="noopener"
                        sx={{ display: 'block', mb: 2, color: '#4F46E5' }}
                    >
                        View Receipt
                    </MuiLink>
                )}
                
                <Box display="flex" justifyContent="center" gap={2}>
                    <Button
                        variant="contained"
                        component={Link}
                        to="/settings/payments/showSubscription"
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#4F46E5',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#3E3AB0',
                            },
                        }}
                    >
                        Upgrade Plan
                    </Button>
                    
                    {/* Conditional rendering for buttons */}
                    {receiptUrl ? (
                        <Button
                            variant="outlined"
                            onClick={handleCheckPurchaseStatus}
                            sx={{
                                textTransform: 'none',
                                borderColor: '#4F46E5',
                                color: '#4F46E5',
                                '&:hover': {
                                    borderColor: '#3E3AB0',
                                    color: '#3E3AB0',
                                },
                            }}
                        >
                            Show Report
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            onClick={handlePurchase}
                            sx={{
                                textTransform: 'none',
                                borderColor: '#4F46E5',
                                color: '#4F46E5',
                                '&:hover': {
                                    borderColor: '#3E3AB0',
                                    color: '#3E3AB0',
                                },
                            }}
                            disabled={purchasedLoader}
                        >
                            {purchasedLoader ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Buy Report'
                            )}
                        </Button>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default LimitModal;